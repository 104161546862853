import { Controller } from "@hotwired/stimulus";
import { sleep } from "../../javascripts/utils";

export default class ScrollToItem extends Controller<HTMLElement> {
  static values = {
    id: String,
  };

  static classes = ["container"];

  declare containerClass: string;
  declare idValue: string;
  declare currentItem: HTMLElement;
  declare container: HTMLElement;

  connect() {
    this.currentItem = this.element.querySelector(
      `[data-lesson-id='${this.idValue}']`
    );

    sleep(0)
      .then(() => {
        this.container = this.element.querySelector(`.${this.containerClass}`);
        this.scrollToItem();
        return;
      })
      .catch((err) => {
        /* eslint-disable no-console */
        console.log(err);
        /* eslint-enable no-console */
      });
  }

  getItemPosition(): number {
    return this.currentItem.getBoundingClientRect().y;
  }

  getContainerPosition(): number {
    return this.container.getBoundingClientRect().y;
  }

  getItemHeight(): number {
    return this.currentItem.clientHeight;
  }

  getOffset(): number {
    const offset = this.getItemPosition() - this.getContainerPosition();
    return offset - this.getItemHeight();
  }

  scrollToItem() {
    const scrollToPosition = this.getOffset();
    this.container.scrollTop = scrollToPosition;
  }
}
