import { Controller } from "@hotwired/stimulus";
import { throttle } from "../../javascripts/utils";

export default class extends Controller {
  static values = { percentToHide: Number };

  declare percentToHideValue: number;

  connect() {
    this.toggleHidden = this.toggleHidden.bind(this);
    window.addEventListener("scroll", throttle(this.toggleHidden, 200));
    window.addEventListener("scrollend", throttle(this.toggleHidden, 200));
  }

  disconnect() {
    window.removeEventListener("scroll", this.toggleHidden);
    window.removeEventListener("scrollend", this.toggleHidden);
  }

  toggleHidden() {
    this.element.classList.toggle(
      "d-none",
      this.getScrollPercent() >= this.percentToHideValue
    );
  }

  getScrollPercent() {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    return Math.floor((scrollTop / (scrollHeight - clientHeight)) * 100);
  }
}
