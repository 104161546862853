const SELECTOR_NAV_TOGGLE = '.js-nav-toggle';
const EXPIRY_BANNER_TOGGLE = ".js-expiry-banner";
const APP_SMART_BANNER = ".js_smartbanner";
const SELECTOR_HEADER = '.bm-header';
const SELECTOR_NAV_CONTENT = '.bm-navbar-content';
const SELECTOR_HEADER_LOGO = '.bm-header__logo';
const SELECTOR_SCROLL_TO = '.js-scroll-to';

const CLASS_BTN_TOGGLED = 'js-nav-toggled';
const CLASS_NAV_EXPANDED = 'js-nav-expanded';
const CLASS_HEADER_DARK = 'js-header-dark';
const CLASS_LOGO_INVERT = 'js-logo-inverted';
const NAVIGATION_BREAKPOINT = 992;

export default function setupMobileNav() {
  let expiryBanner;
  let appBanner;
  let navigationHeader;
  let campaignBanner;

  // Initialize
  mobileNavInit();
  $(document).on('turbolinks:load', () => {
    mobileNavInit();
  });

  // Collapse when navigating away
  $(document).on('turbolinks:before-cache', () => {
    navCollapse();
  });

  // Collapse navbar when resizing
  $(window).resize(() => {
    if (timer) {
      clearTimeout(timer);
    }
    const timer = setTimeout(() => {
      if ($(window).width() >= 992) {
        $(window).trigger('onNavResizeEnd');
      }
    }, 500);
  });

  $(window).bind('onNavResizeEnd', () => {
    navCollapse();
  });

  // Collapse nav if nav scroll link clicked
  $(document).on('click', SELECTOR_SCROLL_TO, () => {
    if ($(window).width() <= 992) {
      navCollapse();
    }
  });

  // Actions
  function mobileNavInit() {
    if ($(SELECTOR_NAV_TOGGLE).length) {
      $(SELECTOR_NAV_TOGGLE).on('click', e => {
        const target = $(e.target).attr('data-target');

        if (!$(target).hasClass(CLASS_NAV_EXPANDED)) {
          navExpand(e.target, target);
          pauseAllVideos();
        } else {
          navCollapse(e.target, target);
          resumePausedVideos();
        }
      });
    }
  }

  function navExpand(btn, target) {
    // If we have an expiry banner, position out of the flow of the document so that the menu is fixed to the top of the page
    expiryBanner = document.querySelector(EXPIRY_BANNER_TOGGLE);
    campaignBanner = document.querySelector(".vc-campaign-top-banner");
    navigationHeader = document.querySelector(SELECTOR_HEADER);
    appBanner = document.querySelector(APP_SMART_BANNER);

    if (expiryBanner && window.innerWidth < NAVIGATION_BREAKPOINT) {
      expiryBanner.classList.add("position-absolute");
    }

    if (campaignBanner && window.innerWidth < NAVIGATION_BREAKPOINT) {
      campaignBanner.classList.add("position-absolute");
      campaignBanner.classList.add("w-100");
    }

    if (window.innerWidth < NAVIGATION_BREAKPOINT) {
      navigationHeader.style.top = "0";
    }

    if (appBanner) {
      document.documentElement.style.marginTop = null;
    }

    $(target).addClass(CLASS_NAV_EXPANDED);
    $(btn).addClass(CLASS_BTN_TOGGLED);
    $(btn).attr('aria-expanded', 'true');
    $(SELECTOR_HEADER).addClass(CLASS_HEADER_DARK);
    $(SELECTOR_HEADER_LOGO).addClass(CLASS_LOGO_INVERT);
  }

  function navCollapse(
    btn = SELECTOR_NAV_TOGGLE,
    target = SELECTOR_NAV_CONTENT
  ) {
    expiryBanner = document.querySelector(EXPIRY_BANNER_TOGGLE);
    navigationHeader = document.querySelector(SELECTOR_HEADER);
    appBanner = document.querySelector(APP_SMART_BANNER);
    campaignBanner = document.querySelector(".vc-campaign-top-banner");
    const appBannerHeight = appBanner
      ? appBanner.getBoundingClientRect().height
      : null;
    // If we have an expiry banner, position the banner back in the flow of the document by restoring its initial position value e.g. position: sticky
    if (expiryBanner) {
      expiryBanner.classList.remove("position-absolute");
    }

    if (campaignBanner) {
      campaignBanner.classList.remove("position-absolute");
      campaignBanner.classList.remove("w-100");
    }

    if (
      expiryBanner &&
      expiryBanner.classList.contains("vc-expiry-banner--sticky") &&
      !expiryBanner.classList.contains("d-none")
    ) {
      navigationHeader.style.top = "auto";
    }

    if (appBanner) {
      document.documentElement.style.marginTop = `${appBannerHeight}px`;
    }

    $(target).removeClass(CLASS_NAV_EXPANDED);
    $(btn).removeClass(CLASS_BTN_TOGGLED);
    $(btn).attr('aria-expanded', 'false');
    $(SELECTOR_HEADER).removeClass(CLASS_HEADER_DARK);
    setTimeout(() => {
      $(SELECTOR_HEADER_LOGO).removeClass(CLASS_LOGO_INVERT);
    }, 400);
  }

  function pauseAllVideos() {
    const $videoArray = $('.vjs-bm.vjs-playing video');

    if ($videoArray.length) {
      $videoArray.each((ind, video) => {
        video.pause();
        video.setAttribute('data-mobile-menu-pause', 'true');
      });
    }
  }

  function resumePausedVideos() {
    $('[data-mobile-menu-pause]').each((ind, video) => {
      video.play();
      video.removeAttribute('data-mobile-menu-pause');
    });
  }
}
