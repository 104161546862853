import { getCSRFToken } from "../utils";

export default function sendSegmentPresalesTrailerPlayed(player) {
  const trailerWatchEndpoint = "/track_play_course_trailer";

  player.on("play", () => {
    fetch(trailerWatchEndpoint, {
      method: "POST",
      headers: {
        "X-CSRF-Token": getCSRFToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        course_id: player.el().dataset.courseId,
        variant_name: player.el().dataset.trackingVariantName,
      }),
    });
  });
}
