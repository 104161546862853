import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  private hiddenClass = "d-none";

  static targets = ["button", "content"];
  static values = {
    buttonCollapsed: String,
    buttonExpanded: String,
    open: Boolean,
  };

  toggle() {
    this.openValue = !this.openValue;
    this.toggleItems();
    this.updateButtonText();
    this.updateButtonAria();
    this.buttonTarget.blur();
    if (!this.openValue) {
      this.scrollToButtonTarget();
    }
  }

  toggleItems() {
    this.contentTargets.forEach((item) =>
      item.classList[this.openValue ? "remove" : "add"](this.hiddenClass)
    );
  }

  updateButtonText() {
    this.buttonTarget.textContent = this.openValue
      ? this.buttonExpandedValue
      : this.buttonCollapsedValue;
  }

  updateButtonAria() {
    this.buttonTarget.setAttribute("aria-expanded", `${this.openValue}`);
  }

  scrollToButtonTarget() {
    this.buttonTarget.scrollIntoView({
      block: "end",
    });
  }
}
