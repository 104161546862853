import { canAccessLocalStorage } from "../../javascripts/utils";

export default function setupCaptionsSaving(
  player,
  {
    localStorageKey = "bbcm-captions",
    localtStorageLanguageKey = "bbcm-captions-language",
  } = {}
) {
  if (!canAccessLocalStorage()) return;

  const tracks = player.textTracks();
  const captionButtons = document.querySelectorAll(
    "button.vjs-subs-caps-button"
  );

  tracks.addEventListener("change", () => {
    const tracks = player.textTracks();

    for (let i = 0; i < tracks.length; i++) {
      const track = tracks[i];
      if (track.kind === "captions" && track.mode === "showing") {
        captionButtons.forEach((button) => {
          button.setAttribute("aria-pressed", "true");
        });
        localStorage.setItem(localtStorageLanguageKey, track.language);
        localStorage.setItem(localStorageKey, track.mode);
        break;
      } else {
        captionButtons.forEach((button) => {
          button.setAttribute("aria-pressed", "false");
        });
        localStorage.removeItem(localStorageKey);
        localStorage.removeItem(localtStorageLanguageKey);
      }
    }
  });

  // Use player.ready to restore the track after VideoJS
  // picks a preferred track (unfortunately, no sign of an API
  // to access this `userPref` referenced in their code)
  // https://github.com/videojs/video.js/blob/affc0611d978b1a6d02e443a46d14a58f2b1441c/src/js/tracks/text-track-display.js#L130
  player.ready(() => {
    restoreTextTrackMode();
  });

  function restoreTextTrackMode() {
    const savedLanguage = localStorage.getItem(localtStorageLanguageKey);
    const savedMode = localStorage.getItem(localStorageKey);

    const tracks = player.textTracks();
    const defaultEnglishLanguage = ["en-GB", "en-US"];

    // If user has existing showing then we want to show either en-GB or en-US
    if (savedMode == "showing" && !savedLanguage) {
      captionButtons.forEach((button) => {
        button.setAttribute("aria-pressed", "true");
      });
      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i];
        if (defaultEnglishLanguage.includes(track.language)) {
          track.mode = "showing";
          localStorage.setItem(localtStorageLanguageKey, track.language);
          localStorage.setItem(localStorageKey, track.mode);
          // If we know we have a match then we can break here
          break;
        }
      }
      return;
    }

    // If there's a saved language and mode, apply them
    if (savedLanguage && savedMode) {
      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i];

        if (track.kind === "captions" && track.language === savedLanguage) {
          track.mode = savedMode;
          captionButtons.forEach((button) => {
            button.setAttribute("aria-pressed", "true");
          });
          localStorage.setItem(localtStorageLanguageKey, track.language);
          localStorage.setItem(localStorageKey, track.mode);
        }
      }
    }
  }
}
