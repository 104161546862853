import { Controller } from "@hotwired/stimulus";
import throttle from "lodash/throttle";

export default class extends Controller {
  declare footer: HTMLElement;

  connect() {
    this.footer = document.querySelector("footer.bm-footer");

    this.onScroll = throttle(this.onScroll.bind(this), 250);
    window.addEventListener("scroll", this.onScroll);
  }

  disconnect() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll() {
    const show = !this.isScrollAtTop() && !this.isScrollAtFooter();

    this.element.classList.toggle("hide", !show);
    this.element.classList.toggle("pe-none", show);

    this.element.classList.toggle("show", show);
    this.element.classList.toggle("pe-none", !show);
  }

  isScrollAtTop() {
    return window.scrollY === 0;
  }

  isScrollAtFooter() {
    const boundaries = this.footer.getBoundingClientRect();
    const height = window.innerHeight || document.documentElement.clientHeight;
    return boundaries.top < height;
  }
}
