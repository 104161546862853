export const appearance = {
  theme: "stripe",
  variables: {
    colorPrimary: "#e45f00",
    borderRadius: "0",
    spacingGridRow: "16px",
    fontFamily: "Roboto Regular, sans-serif",
  },
  rules: {
    ".Input, .Block, .CheckboxInput, .CodeInput": {
      boxShadow: "none",
      borderLeft: "none",
      borderTop: "none",
      borderRight: "none",
      borderBottomWidth: "2px",
      borderBottomColor: "transparent",
    },
    ".Input": {
      paddingTop: "1rem",
    },
    ".Input:focus": {
      boxShadow: "none",
      borderBottomColor: "#e45f00",
      outline: "none",
    },
    ".Label": {
      color: "#555",
      fontSize: "0.85rem",
    },
  },
};
