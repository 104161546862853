const SELECTOR = ".js-scroll-to";

const easeOutCubic = (t) => --t * t * t + 1;

const scrollTo = (yPos, duration, callback) => {
  const startY = window.scrollY;
  const difference = yPos - startY;
  const startTime = performance.now();

  const step = () => {
    const progress = (performance.now() - startTime) / duration;
    const amount = easeOutCubic(progress);
    window.scrollTo({ top: startY + amount * difference });
    if (progress < 0.99) {
      window.requestAnimationFrame(step);
    } else {
      callback();
    }
  };

  step();
};

export default function setupScrollTo() {
  $(document).on("click", SELECTOR, (event) => {
    const targetSelector = event.currentTarget
      .getAttribute("href")
      .replace(/\//g, "");
    const target = document.querySelector(targetSelector);

    if (target) {
      event.preventDefault();

      let scrollPosition =
        target.getBoundingClientRect().top +
        (window.pageYOffset || document.documentElement.scrollTop);

      const header = document.querySelector("body > header");

      if (header) {
        scrollPosition -= header.getBoundingClientRect().height + 20;
      }

      scrollTo(scrollPosition, 600, () => {
        document.querySelectorAll(":focus").forEach((el) => el.blur());

        if (
          document.documentElement.clientWidth >= 992 &&
          !event.target.dataset.skipFocusFirstLink
        ) {
          const childLink = target.querySelector("a");
          if (childLink) {
            childLink.focus();
          }
        } else {
          target.focus();
        }
      });
    }
  });
}
