import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  show() {
    this.element.dispatchEvent(new Event("show"));
    this.element.classList.toggle("d-none", false);
  }

  close() {
    this.element.dispatchEvent(new Event("close"));
    this.element.remove();
  }
}
