export default function payWithSavedCard() {
  const $form__SavedMethod = $(".js-stripe-form__saved");

  if ($form__SavedMethod.length) {
    const stripe__SavedMethod = window.Stripe(
      $form__SavedMethod.attr("data-stripe-publishable-key")
    );

    // eslint-disable-next-line
    var submitButton__SavedMethod = document.querySelector(
      ".js-stripe-submit__saved"
    );

    // eslint-disable-next-line
    var form__SavedMethod = document.getElementById("payment-form__saved");

    $(form__SavedMethod).on("submit", (event) => {
      event.preventDefault();
      event.stopImmediatePropagation();

      if (userDetailsValid()) {
        submitSavedStripeForm(
          event,
          stripeIntentClientSecret(),
          stripe__SavedMethod
        );
      }
    });
  }

  function stripeIntentClientSecret() {
    return $(".js-stripe-intent-secret__saved").val();
  }

  function submitSavedStripeForm(e, clientSecret, stripe__SavedMethod) {
    e.preventDefault();
    e.stopImmediatePropagation();
    window.disableModal();

    submitButton__SavedMethod.setAttribute("disabled", "disabled");
    submitButton__SavedMethod.setAttribute(
      "value",
      "Please wait, payment in progress"
    );
    stripe__SavedMethod
      .confirmCardPayment(clientSecret, {
        payment_method: $(submitButton__SavedMethod).attr(
          "data-payment-method-id"
        ),
        setup_future_usage: "off_session",
      })
      .then((result) => {
        if (result.error) {
          const errors = $("#card-errors__saved");
          errors
            .addClass("form-error-field")
            .empty()
            .append(
              "We’re sorry but your payment was unsuccessful, please check your card details or contact your bank"
            );
          submitButton__SavedMethod.removeAttribute("disabled");
          submitButton__SavedMethod.setAttribute(
            "value",
            $(submitButton__SavedMethod).attr("data-original-title")
          );

          // If survey input is on checkout, it needs to shift up
          //  to avoid overlap with error message box.
          const survey_input = $("#survey-input");
          if (survey_input) {
            survey_input.addClass(
              "bm-checkout__survey--position-above-payment-errors"
            );
          }

          window.enableModal();
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === "succeeded") {
            $(".js-stripe-intent-id__saved").val(result.paymentIntent.id);

            // Remove event to prevent a loop
            $(form__SavedMethod).off("submit");
            //eslint-disable-next-line
            Rails.fire($('#payment-form__saved')[0], 'submit');
          }
        }
        return null;
      })
      .catch(() => undefined);
  }

  function userDetailsValid() {
    window.setupPaymentValidation($form__SavedMethod);

    if (!$form__SavedMethod.find(".is-invalid").length) {
      return true;
    } else {
      return false;
    }
  }
}
