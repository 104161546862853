import videojs from 'video.js';
import "videojs-hotkeys";
import { getMobileOperatingSystem, skipTime } from "./helpers";
import { playNextLesson } from './play_next_lesson';
import { getNextLessonUrl } from './setup_next_lesson_autoplay';

function setPlaying(el: HTMLSpanElement, playing: boolean) {
  const state = playing ? "Play" : "Pause";
  el.lastChild.textContent = state;
}

export default function setupCustomElements(player) {
  if (!$(player.el()).attr('data-custom-components')) {
    const controlBar = player.controlBar.el();
    const VjsButton = videojs.getComponent('Button');

    player.controlBar.playToggle.el().addEventListener("click", () => {
      player.focus(); // ensure that Hotkeys still work after clicking the "Play toggle" button
    });

    // Add skip back button
    class skipBackButton extends VjsButton {
      constructor(player, options) {
        super(player, options);
      }
      createEl(tag = "button", props = {}, attributes = {}) {
        const el = super.createEl(tag, props, attributes);
        el.lastChild.textContent = "10s";
        el.lastChild.setAttribute("aria-hidden", "true");
        const srText = document.createElement("span");
        srText.classList.add("sr-only");
        srText.textContent = "Skip Back 10 seconds";
        el.appendChild(srText);
        return el;
      }
      buildCSSClass() {
        return `vjs-skip-back vjs-control`;
      }
      handleClick() {
        skipTime(player, 10, true);
        player.focus(); // ensure that Hotkeys continue to function, after clicking this button
      }
    }
    VjsButton.registerComponent('skipBackButton', skipBackButton);

    class playPauseButton extends VjsButton {
      constructor(player, options) {
        super(player, options);
      }
      createEl(tag = "button", props = {}, attributes = {}) {
        return super.createEl(tag, props, attributes);
      }
      buildCSSClass() {
        return `vjs-play-pause vjs-control`;
      }
      handleClick() {
        if (player.paused()) {
          player.play();
          setPlaying(this.el(), true);
        } else {
          player.pause();
          setPlaying(this.el(), false);
        }
        player.focus(); // ensure that Hotkeys continue to function, after clicking this button
      }
    }
    VjsButton.registerComponent("playPauseButton", playPauseButton);

    // Add skip forward button
    class skipForwardButton extends VjsButton {
      constructor(player, options) {
        super(player, options);
      }
      createEl(tag = "button", props = {}, attributes = {}) {
        const el = super.createEl(tag, props, attributes);
        el.lastChild.textContent = "10s";
        el.lastChild.setAttribute("aria-hidden", "true");
        const srText = document.createElement("span");
        srText.classList.add("sr-only");
        srText.textContent = "Skip Forward 10 seconds";
        el.appendChild(srText);
        return el;
      }
      buildCSSClass() {
        return `vjs-skip-forward vjs-control`;
      }
      handleClick() {
        skipTime(player, 10);
        player.focus(); // ensure that Hotkeys continue to function, after clicking this button
      }
    }
    VjsButton.registerComponent('skipForwardButton', skipForwardButton);

    const skipBackBtn = new skipBackButton(player);
    const skipForwardBtn = new skipForwardButton(player);
    const playPauseBtn = new playPauseButton(player);
    // Create skip container as regular element
    const $newSkipContainer = $(document.createElement('div'));
    $newSkipContainer.addClass('vjs-skip-container');
    $newSkipContainer
      .append(skipBackBtn.el())
      .append(playPauseBtn.el())
      .append(skipForwardBtn.el());
    $(player.controlBar.el()).prepend($newSkipContainer);

    player.on("play", () => {
      setPlaying(playPauseBtn.el(), false);
    });

    player.on("pause", () => {
      setPlaying(playPauseBtn.el(), true);
    });

    // Add custom fullscreen button
    class fullScreenButton extends VjsButton {
      constructor(player, options) {
        super(player, options);
      }
      createEl(
        tag = 'button',
        props = { title: 'Fullscreen' },
        attributes = {}
      ) {
        let el = super.createEl(tag, props, attributes);
        return el;
      }
      buildCSSClass() {
        return `vjs-fullscreen-control vjs-control`;
      }
      handleClick() {
        // Placed to prevent native fullscreen from activating with other gestures such as swipe or touch.
        if (player.isFullscreen()) {
          player.exitFullscreen();
        } else {
          player.requestFullscreen();
          player.focus(); // ensure that Hotkeys continue to function, after clicking this button
        }
      }
    }
    VjsButton.registerComponent('fullScreenButton', fullScreenButton);

    const fullScreenBtn = new fullScreenButton(player);

    // We don't want to add the fullscreen button when the portrait version of the trailer
    // is present as it leads to this bug (https://bbcmaestro.atlassian.net/browse/BM-2314).

    if (
      getMobileOperatingSystem() !== "iPhone" ||
      player.el().dataset.portraitTrailer == undefined
    ) {
      player.controlBar.addChild(fullScreenBtn);
    }

    // Add volume bar
    const volumeRevealEl =
      "<ul class='vjs-volume-reveal'><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>";
    $(controlBar)
      .find('.vjs-volume-bar.vjs-slider-horizontal')
      .append(volumeRevealEl);

    // Change time divider from '/'
    $(controlBar)
      .find('.vjs-time-divider div span')
      .text('-');

    const volumeButton = $(player.controlBar.volumePanel.el()).find(
      '.vjs-mute-control'
    );

    if (!player.muted()) {
      volumeButton.attr('aria-pressed', 'false');
    } else {
      volumeButton.attr('aria-pressed', 'true');
    }

    volumeButton.on('click touchend', () => {
      const wasPressed = volumeButton.attr('aria-pressed') == 'true';
      volumeButton.attr('aria-pressed', !wasPressed);

      // Required to explicitly set muted state on mobile devices
      // Prevents button icon state from going out of sync with muted state.
      const mutedState = player.muted();
      player.muted(mutedState);
      player.focus(); // ensure that Hotkeys continue to function, after clicking this button
    });

    // Add Lesson number & title
    const maestro = player.tagAttributes['data-maestro'];
    const lessonNumber = player.tagAttributes['data-lesson-number'];
    const lessonTitle = player.tagAttributes['data-lesson-title'];

    if (maestro && lessonTitle) {
      const timeControl = $(controlBar).find('.vjs-duration');

      const titleHTML = `
        <div class="vjs-lesson-title bm-bullets-center">
          <span class="vjs-lesson-title__maestro">
            ${maestro}
          </span>
          <span class="bm-bullet-light" role="presentation"></span>
          <span class="vjs-lesson-title__announcement" aria-live="polite">
            <span class="${lessonNumber.length ? '' : 'd-none'}">
              <span class="vjs-lesson-title__lesson-number">
                ${
                  lessonNumber === 'Taster Lesson'
                    ? 'Taster Lesson'
                    : 'Lesson ' + lessonNumber
                }
              </span>
              <span class="bm-bullet-light ${
                lessonNumber.length ? '' : 'd-none'
              }" role="presentation"></span>
            </span>
            <span class="vjs-lesson-title__lesson-title">
              ${lessonTitle}
            </span>
          </span>
        </div>`;

      const $titleElement = $(titleHTML);
      $titleElement.insertAfter(timeControl);

      if (!player.isFullscreen()) {
        $titleElement.addClass("hidden");
      }

      player.on("fullscreenchange", () => {
        if (player.isFullscreen()) {
          $titleElement.removeClass("hidden");
        } else {
          $titleElement.addClass("hidden");
        }
      });
    }

    // Add next button as a VJS component
    class nextButton extends VjsButton {
      constructor(player, options) {
        super(player, options);
      }
      createEl(
        tag = "button",
        props = { title: "Next Lesson" },
        attributes = {}
      ) {
        return super.createEl(tag, props, attributes);
      }
      buildCSSClass() {
        return `vjs-next-lesson ${super.buildCSSClass()}`;
      }
      async handleClick() {
        // Prevent multiple clicks on the button
        if (this.isLoading) return;
        const player = this.player();

        if (player.tagAttributes["data-next-lesson-path"].length) {
          this.isLoading = true;
          this.player().pause();
          const url = getNextLessonUrl(this.player(), true);
          await playNextLesson(this.player(), url);
          this.isLoading = false;
        }
      }
    }
    VjsButton.registerComponent("nextButton", nextButton);

    const nextBtn = new nextButton(player);
    player.controlBar.addChild(nextBtn, null, 6);

    player.ready(() => {
      player.hotkeys({
        volumeStep: 0.1,
        seekStep: 10,
        enableModifiersForNumbers: false,
        enableHoverScroll: true,
        // avoid focusing video on autoplay due to chrome browser scroll-to-focus behaviour
        // videojs-hotkeys: https://github.com/ctd1500/videojs-hotkeys/issues/44
        // chrome behaviour: https://chromium.googlesource.com/chromium/src/+/537603bf2f2ab1bea740bbb4137a774be76aeab5%5E%21/
        skipInitialFocus: true,
      });
    });

    // Used as a check to prevent custom component being
    // created twice
    $(player.el()).attr('data-custom-components', 'true');
  }
}
