import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["status", "primary", "secondary"];
  static values = {
    onPrimary: String,
    onSecondary: String,
    offPrimary: String,
    offSecondary: String,
    onStatus: String,
    offStatus: String,
  };

  change(e: Event) {
    const target = e.target as HTMLInputElement;
    if (target.checked) {
      this.statusTarget.textContent = this.onStatusValue;
      this.primaryTarget.textContent = this.onPrimaryValue;
      this.secondaryTarget.textContent = this.onSecondaryValue;
    } else {
      this.statusTarget.textContent = this.offStatusValue;
      this.primaryTarget.textContent = this.offPrimaryValue;
      this.secondaryTarget.textContent = this.offSecondaryValue;
    }
  }
}
