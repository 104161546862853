import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item"];

  connect() {
    const item = this.itemTargets[0];
    const itemWidth = item.getBoundingClientRect().width;

    // screen.width does not change with zoom on Chrome but does in Firefox
    // https://bbcmaestro.slack.com/archives/C052N65L72P/p1698241804767949?thread_ts=1698240854.263809&cid=C052N65L72P
    const maxWidth = Math.max(window.innerWidth, screen.width);
    const copies = Math.ceil(maxWidth / itemWidth);

    for (let i = 0; i < copies; i++) {
      const clone = item.cloneNode(true);
      clone.setAttribute("aria-hidden", "true");
      this.itemTargets[0].parentNode.appendChild(clone);
    }

    this.element.style.setProperty("--marquee-item-count", 1 + copies);
  }
}
