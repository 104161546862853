const SELECTOR_CLASS_ENDED = 'vjs-ended';
const SELECTOR_CLASS_HAS_STARTED = 'vjs-has-started';

export default function playWhenVisible(player) {
  const playerObserver = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        playVideo(player);
      } else {
        pauseVideoIfStarted(player);
      }
    });
  });
  playerObserver.observe(player.el());
}

function playVideo(player) {
  if (!player.el().classList.contains(SELECTOR_CLASS_ENDED)) {
    player.play();
  }
}

function pauseVideoIfStarted(player) {
  if (player.el().classList.contains(SELECTOR_CLASS_HAS_STARTED)) {
    player.pause();
  }
}
