const SELECTOR = '.js-custom-flash-banner';
const SELECTOR_CLOSE = '.js-custom-flash-banner-close';

export default function setupCustomFlashBanner() {
  customFlashBannerInit();
  $(document).on('turbolinks:load', customFlashBannerInit);
}

function customFlashBannerInit() {
  if ($(SELECTOR).length) {
    $(document).on('click', SELECTOR_CLOSE, removeBanner);
    $(document).on('turbolinks:before-cache', removeBanner);

    respositionContentForBanner();

    // Adjust header & main positioning on viewport resize
    $(window).resize(() => {
      if (timer) {
        clearTimeout(timer);
      }
      const timer = setTimeout(() => {
        $(window).trigger('onFlashBannerResizeEnd');
      }, 200);
    });

    $(window).bind('onFlashBannerResizeEnd', () => {
      respositionContentForBanner();
    });
  }
}

function respositionContentForBanner() {
  const height = $(SELECTOR).outerHeight(true);
  $('header').css('top', height);
  $('main').css('margin-top', height);
}

function removeBanner() {
  $('.bm-custom-flash-banner').remove();
  $(document).off('click', removeBanner);
  $(document).off('turbolinks:before-cache', removeBanner);

  $('header').css('top', '0');
  $('main').css('margin-top', '0');
}
