import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "input", // multiple input targets accepted
    "submit",
  ];

  static values = { disableWith: String };

  declare disableWithValue: string;

  connect() {
    this.originalDisableWith =
      this.submitTarget.getAttribute("data-disable-with");

    this.bindMethods();

    // disable submit on load if input fields are empty
    this.onInput();

    this.inputTargets.forEach((input) => {
      input.addEventListener("input", this.onInput);
    });
  }

  disconnect() {
    this.inputTargets.forEach((input) => {
      input.removeEventListener("input", this.onEmailInputChange);
    });
  }

  bindMethods() {
    this.onInput = this.onInput.bind(this);
  }

  onInput() {
    for (const input of this.inputTargets) {
      if (input.value.length == 0) {
        this.disable();
        return;
      }
    }
    this.enable();
  }

  disable() {
    this.submitTarget.setAttribute(
      "data-disable-with",
      this.submitTarget.value
    );
    this.submitTarget.disabled = true;
  }

  enable() {
    this.submitTarget.setAttribute(
      "data-disable-with",
      this.originalDisableWith || this.submitTarget.value
    );
    this.submitTarget.disabled = false;
  }
}
