import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

document.addEventListener("DOMContentLoaded", () => {
  const sentryDsnTag = document.querySelector("meta[name=sentry-js-dsn]");

  if (sentryDsnTag) {
    const dsn = document.querySelector("meta[name=sentry-js-dsn]").content;
    Sentry.init({
      dsn,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.1,
      initialScope: (scope) => {
        const scopeFromMeta = JSON.parse(
          document.querySelector("meta[name=sentry-js-scope]").content
        );

        scope.setUser(scopeFromMeta.user);
        return scope;
      },
    });
  }
});
