import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  private overflowClass = "overflow-hidden";

  static targets = ["dialog"];

  connect() {
    this.dialog = document.getElementById("dialog");
    if (!this.dialog) return;

    this.onClose = this.onClose.bind(this);
    this.dialogTarget.addEventListener("close", this.onClose);
  }

  disconnect() {
    if (!this.dialog) return;
    this.dialogTarget.removeEventListener("close", this.onClose);
  }

  onClose() {
    this.enableScroll();
  }

  close() {
    this.dialogTarget.close();
    this.enableScroll();
  }

  showModal() {
    this.dialogTarget.showModal();
    this.disableScroll();
  }

  enableScroll() {
    document.body.classList.remove(this.overflowClass);
    document.documentElement.classList.remove(this.overflowClass);
  }

  disableScroll() {
    document.body.classList.add(this.overflowClass);
    document.documentElement.classList.add(this.overflowClass);
  }
}
