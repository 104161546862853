import { canAccessLocalStorage } from "../../../javascripts/utils";

// Note:
// If adding another language we will need to update the LANGUAGE_MAP constant below
// as well as the Subtitle model in app/models/subtitle.rb to ensure additional
// languages are passed onto Segment

const LANUGAGE_MAP = Object.freeze({
  "es-ES": "Spanish",
  "en-GB": "English",
  "en-US": "English",
});

export function getCurrentCaptionSetting() {
  if (!canAccessLocalStorage()) return;

  return LANUGAGE_MAP[localStorage.getItem("bbcm-captions-language")] || "Off";
}
