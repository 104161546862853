export default function updateCard() {
  const modal = document.querySelector(".modal");
  const $form__UpdateMethod = $('.js-stripe-form__update');

  if ($form__UpdateMethod.length) {
    var form__UpdateMethod = document.getElementById('payment-form__update');
    const stripe__UpdateMethod = window.Stripe(
      $form__UpdateMethod.attr('data-stripe-publishable-key')
    );
    const clientSecret__UpdateMethod = form__UpdateMethod.dataset.secret;
    const elements__UpdateMethod = stripe__UpdateMethod.elements({
      mode: "setup",
      setupFutureUsage: "off_session",
      currency: "gbp",
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css?family=Roboto:400'
        }
      ]
    });

    // Custom styling can be passed to options when creating an Element.
    var style = {
      base: {
        iconColor: '#555',
        color: '#555',
        fontWeight: 400,
        fontFamily: '"Roboto", sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',

        '::placeholder': {
          color: '#999'
        },
        ':-webkit-autofill': {
          color: '#555'
        }
      },
      invalid: {
        iconColor: 'red',
        color: '#b00020'
      }
    };

    // TO DO: Make the init code reusable for other card forms (will revisit when working on billing page)

    // Create the elements
    var cardNumber = elements__UpdateMethod.create('cardNumber', {
      style: style,
      placeholder: ''
    });
    var cardExpiry = elements__UpdateMethod.create('cardExpiry', {
      style: style,
      placeholder: ''
    });
    var cardCvc = elements__UpdateMethod.create('cardCvc', {
      style: style,
      placeholder: ''
    });
    var cardPostal = elements__UpdateMethod.create('postalCode', {
      style: style,
      placeholder: ''
    });

    // Handle real-time validation errors
    [
      [cardNumber, "#card-number"],
      [cardExpiry, "#card-expiry"],
      [cardCvc, "#card-cvc"],
      [cardPostal, "#card-postcode"],
    ].forEach((item) => {
      const element = item[0];
      const domID = item[1];
      element.mount(domID);

      element.on("change", (event) => {
        const $displayError = $(domID).siblings(".form-error-field");

        if (event.error) {
          let message = event.error.message;

          if (addressCountry() == "US") {
            message = message.replace(/postal code/i, "zip code");
          }

          $displayError.text(message);
        } else {
          $displayError.removeClass("card-error-message");
          $displayError.empty();
        }
      });
    });

    var submitButton__UpdateMethod = document.querySelector(
      '.js-stripe-submit__update'
    );

    form__UpdateMethod.addEventListener('submit', function(ev) {
      ev.preventDefault();

      const validName = validateName();
      const validAddress = validateAddressFields();

      if (!(validName && validAddress)) {
        // Since this modal is scrollable we want to go back to the top to be clear to the user that
        // there has been an error as it can be unclear if we are at the bottom of the modal window.
        modal.scrollTo(0, 0);
        return false;
      }

      submitButton__UpdateMethod.setAttribute('disabled', 'disabled');
      submitButton__UpdateMethod.innerHTML = "<span>Saving...</span>";

      stripe__UpdateMethod
        .confirmCardSetup(clientSecret__UpdateMethod, {
          payment_method: {
            card: cardNumber,
            billing_details: {
              name: $(".js-name-on-card__update").val(),
              address: {
                // Note: Postcode (Zip code) is automatically populated using a Stripe elements postalCode below
                line1: $("[data-address-line1]").val(),
                country: addressCountry(),
              },
            }
          }
        })
        .then(function(result) {
          if (result.error) {
            const errors = $('#card-errors__update');
            errors
              .addClass('form-error-field')
              .empty()
              .append(
                'We’re sorry but your update was unsuccessful, please check your card details or contact your bank'
              );

            submitButton__UpdateMethod.removeAttribute('disabled');
            submitButton__UpdateMethod.innerHTML = `<span>${submitButton__UpdateMethod.dataset.originalTitle}</span>`;
          } else {
            // The payment has been processed!
            if (result.setupIntent.status === 'succeeded') {
              $('.js-stripe-payment-method-id__update').val(
                result.setupIntent.payment_method
              );
              $form__UpdateMethod.submit();
            }
          }
          return null;
        })
        .catch(function() {});
    });
  }

  function addressCountry() {
    return $("[data-address-country]").data("address-country");
  }

  function validateAddressFields() {
    if (addressCountry() !== "US") {
      return true;
    }

    const $line1 = $("[data-address-line1");
    const line1Label = $line1.siblings("label").text();
    const $line1Error = $line1.siblings(".form-error-field");

    const line1Value = $line1.val() as string;

    if (line1Value.trim() == "") {
      $line1.addClass("is-invalid");
      $line1Error.text(`${line1Label} can't be blank`);
      return false;
    } else {
      $line1.removeClass("is-invalid");
      $line1Error.empty();
      return true;
    }
  }

  // Validation for cardholder name field
  function validateName() {
    $('.invalid-feedback').text('');
    const nameField = $('.js-name-on-card__update');
    const name = $(nameField).val();

    if (/^[a-zA-Z\s]+$/.test(name)) {
      $(nameField).removeClass('is-invalid');
      $(nameField)
        .next()
        .text('');
      return true;
    } else if (name.length === 0) {
      updateNameError(nameField, "Name can't be blank");
    } else {
      updateNameError(nameField, "Name can only contain letters");
    }

    return false;
  }

  function updateNameError(nameField, msg) {
    $(nameField).after(`<div class='invalid-feedback'>${msg}</div>`);
    $(nameField).addClass("is-invalid");
    $(".invalid-feedback").show();
  }
}
