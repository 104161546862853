import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  private hiddenClass = "d-none";
  static targets = ["currentForm", "newForm"];
  switchForm() {
    this.currentFormTarget.classList.add(this.hiddenClass);
    this.newFormTarget.classList.remove(this.hiddenClass);
  }
}
