import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["formContainer", "successContainer"];

  connect() {
    this.bindMethods();
    this.element.addEventListener("ajax:success", this.onAjaxSuccess);
  }

  disconnect() {
    this.element.removeEventListener("ajax:success", this.onAjaxSuccess);
  }

  bindMethods() {
    this.onAjaxSuccess = this.onAjaxSuccess.bind(this);
  }

  onAjaxSuccess() {
    this.successContainerTarget.classList.remove("d-none");
    this.formContainerTarget.classList.replace("d-flex", "d-none");
  }
}
