const SELECTOR = '.js-payments-collapse';
const SELECTOR_SECTION = '.js-payment-history';

export default function setupTableCollapseScroll() {
  $(document).on('turbolinks:load', () => {
    tableCollapseScrollInit();
  });
  tableCollapseScrollInit();

  function tableCollapseScrollInit() {
    if ($(SELECTOR).length) {
      $(SELECTOR).on('click', e => {
        if ($(e.target).attr('aria-expanded') === 'true') {
          $('html, body').animate({
            scrollTop: $(SELECTOR_SECTION).offset().top - 60
          });
        }
      });
    }
  }
}
