import { Controller } from "@hotwired/stimulus";
import { canAccessLocalStorage } from "../../javascripts/utils";

export default class extends Controller {
  static values = {
    campaignId: String,
  };

  connect() {
    this.maybeShowPopup();

    this.onClose = this.onClose.bind(this);
    this.element.addEventListener("close", this.onClose);
  }

  disconnect() {
    this.element.removeEventListener("close", this.onClose);
  }

  maybeShowPopup() {
    this.element.classList.toggle("d-none", !this.showPopup());
  }

  showPopup() {
    if (!canAccessLocalStorage()) return;
    // Show the popup if the user has not dismissed it
    if (!this.dismissedAt()) return true;

    // Show the popup only if the user has not dismissed it today
    return !this.datesAreOnSameDay(new Date(), this.dismissedAt());
  }

  dismissedAt() {
    const dismissedAtString = localStorage.getItem(
      this.campaignKey("dismissed_at")
    );
    return dismissedAtString ? new Date(dismissedAtString) : null;
  }

  onClose() {
    localStorage.setItem(
      this.campaignKey("dismissed_at"),
      new Date().toISOString()
    );
  }

  campaignKey(key) {
    return `campaign/${this.campaignIdValue}.${key}`;
  }

  datesAreOnSameDay(first: Date, second: Date) {
    return first.toDateString() === second.toDateString();
  }
}
