import { Controller } from "@hotwired/stimulus";
import { appearance } from "../config/stripe/theme";

export default class extends Controller {
  static values = {
    stripePublishableKey: String,
    paymentMethodId: String,
    paymentIntentClientSecret: String,
    returnUrl: String,
    noValidPaymentMethod: Boolean,
  };

  static targets = [
    "newCardForm",
    "newCardButton",
    "savedCard",
    "submit",
    "spinner",
    "message",
    "elementContainer",
  ];

  showNewCardForm() {
    this.mode = "new_payment_method";
    this.newCardFormTarget.classList.remove("d-none");
    this.newCardButtonTarget.classList.add("d-none");
    this.savedCardTarget.classList.add("d-none");
    this.messageTarget.classList.add("hidden");
    this.messageTarget.textContent = "";
  }

  connect(): void {
    this.mode = "existing_payment_method";

    this.stripe = Stripe(this.stripePublishableKeyValue);

    const paymentElementOptions = {
      layout: "tabs",
      paymentMethodOrder: ["card"],
      terms: {
        card: "never",
      },
    };

    this.elements = this.stripe.elements({
      appearance,
      clientSecret: this.paymentIntentClientSecretValue,
    });

    this.paymentElement = this.elements.create(
      "payment",
      paymentElementOptions
    );

    this.paymentElement.mount(this.elementContainerTarget);

    if (this.noValidPaymentMethodValue) {
      this.showNewCardForm();
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    this.setLoading(true);

    const confirmPaymentOptions = {
      confirmParams: {
        return_url: this.returnUrlValue,
      },
    };

    switch (this.mode) {
      case "new_payment_method":
        confirmPaymentOptions.elements = this.elements;
        break;
      case "existing_payment_method":
        confirmPaymentOptions.clientSecret =
          this.paymentIntentClientSecretValue;
        confirmPaymentOptions.confirmParams.payment_method =
          this.paymentMethodIdValue;
        break;
    }

    const { error } = await this.stripe.confirmPayment(confirmPaymentOptions);

    if (error.type === "card_error" || error.type === "validation_error") {
      this.showMessage(error.message);
    } else if (error.code == "payment_intent_authentication_failure") {
      this.showMessage(
        "We could not authenticate this card. Please try again or add a new payment method."
      );
    } else {
      this.showMessage("An unexpected error occurred.");
    }

    this.setLoading(false);
  }

  showMessage(messageText) {
    this.messageTarget.classList.remove("hidden");
    this.messageTarget.textContent = messageText;
  }

  // Show a spinner on payment submission
  setLoading(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      this.submitTarget.disabled = true;
      this.newCardButtonTarget.disabled = true;
      this.spinnerTarget.classList.remove("hidden");
    } else {
      this.submitTarget.disabled = false;
      this.newCardButtonTarget.disabled = false;
      this.spinnerTarget.classList.add("hidden");
    }
  }
}
