// This controller is designed to be on a Logout button
// in order to ensure that any client-side Identity tracking
// session is reset at the same time as the server-side session.

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  resetIdentity() {
    if (window.analytics) window.analytics.reset();
    if (window.mixpanel) {
      window.mixpanel.reset();

      // Reset replay properties in cookies
      const sessionReplayProps = mixpanel.get_session_recording_properties();
      const mixpanelReplayContext = JSON.stringify(sessionReplayProps);
      document.cookie = `mp_replay_context=${mixpanelReplayContext};`;
    }
  }
}
