import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    end: Number,
  };

  static targets = ["timer"];

  declare endValue: number;
  declare timerTarget: HTMLTimeElement;

  connect() {
    // Set the date we're counting down to
    const countDownDate = new Date(this.endValue * 1000).getTime();

    // Update the count down every 1 second
    const interval = setInterval(() => {
      const now = new Date().getTime();

      // Find the distance between now an the count down date
      const delta = countDownDate - now;

      const hours = Math.floor(delta / 3600000); // 1 hour = 3600000 milliseconds

      const remainingMinutes = delta % 3600000;
      const minutes = Math.floor(remainingMinutes / 60000); // 1 minute = 60000 milliseconds

      const remainingSeconds = remainingMinutes % 60000;
      const seconds = Math.floor(remainingSeconds / 1000); // 1 second = 1000 milliseconds

      this.timerTarget.textContent =
        String(hours).padStart(2, "0") +
        "h " +
        String(minutes).padStart(2, "0") +
        "m " +
        String(seconds).padStart(2, "0") +
        "s ";

      if (delta < 0) {
        clearInterval(interval);
        document.location.reload();
      }
    }, 1000);
  }
}
