import { Controller } from "@hotwired/stimulus";
import SmartBanner from "smartbanner.js/src/smartbanner.js";

export default class extends Controller {
  connect() {
    if (!document.querySelector("meta[name='smartbanner:title']")) return;

    const topOfDocument = document.documentElement.style.marginTop;
    const smartbanner = new SmartBanner();
    smartbanner.publish();

    const initialScrollTop = document.documentElement.scrollTop;
    // If the initial scroll position has the same number value
    // as the margin-top added dynamically by smartbanner
    // then we scroll to the top of the page to ensure
    // the banner is visible
    if (`${initialScrollTop}px` == topOfDocument) {
      window.scrollTo(0, 0);
    }
  }
}
