// Modules
import { setupPlayer, revertVideoForCache } from './video';
import setupPauseVideoOnVisibilityChangeOnMobile from './setup_pause_video_on_visibility_change_on_mobile';

const SELECTOR = '.js-video';
const SELECTOR_CONTAINER = '.js-video-cache-container';

export default function setupVideo() {
  // Video elements are cached before initialisation,
  // then restored on 'before-cache'
  let videoCache = [];

  // Cache & setup on initial load
  cacheVideos();
  setup();

  // Pause mobile & tablet videos on visibility change
  setupPauseVideoOnVisibilityChangeOnMobile();

  // Cache & setup on internal visits
  $(document).on('turbolinks:load', () => {
    cacheVideos();
    setup();
  });

  // Destroy all instance's and reset video & source
  $(document).on('turbolinks:before-cache', () => {
    $(SELECTOR_CONTAINER).each((ind, container) => {
      const vid = $(container).find(SELECTOR)[0];
      if (vid) {
        revertVideoForCache(vid, container, videoCache[ind]);
      }
    });
    videoCache = [];
  });

  // The original markup is cached, the reverted before-cache
  // to prevent issues with turbolinks restoring half-initiated
  // players
  function cacheVideos() {
    $(SELECTOR_CONTAINER).each((ind, el) => {
      const $video = $(el).find('video');

      // Autoplay attr breaks lessons player for mobiles/devices that
      // block autoplaying. Checking it's not a background video and
      // removeing autoplay attr for caching
      if ($video.attr('autoplay') && !$video.parent().hasClass('bm-bg-video')) {
        $video.removeAttr('autoplay');
        videoCache.push($(el).html());
        $video.attr('autoplay', 'true');
      } else {
        videoCache.push($(el).html());
      }
    });
  }

  function setup() {
    const players = document.querySelectorAll(SELECTOR);

    for (var i = 0; i < players.length; i++) {
      setupPlayer(players[i]);
    }
  }
}
