import { Controller } from "@hotwired/stimulus";
import { canAccessLocalStorage } from "../../javascripts/utils";

export default class extends Controller {
  static values = {
    resourceId: String,
    resourceType: String,
  };

  connect() {
    this.exitIntent = this.exitIntent.bind(this);
    document.addEventListener("scroll", this.exitIntent);

    this.onClose = this.onClose.bind(this);
    this.element.addEventListener("close", this.onClose);
  }

  disconnect() {
    this.element.removeEventListener("close", this.onClose);
    document.removeEventListener("scroll", this.exitIntent);
  }

  exitIntent() {
    const scroll_speed_threshold = -100;

    if (this.scrollSpeed() < scroll_speed_threshold) {
      this.maybeShowPopup();
    }
  }

  scrollSpeed = (() => {
    let prev_pos, new_pos, timer, delta;
    const delay = 50;

    function clear() {
      prev_pos = null;
      delta = 0;
    }

    clear();

    const calcDelta = () => {
      new_pos = window.scrollY;
      if (prev_pos != null) {
        delta = new_pos - prev_pos;
      }
      prev_pos = new_pos;

      clearTimeout(timer);
      timer = setTimeout(clear, delay);

      return delta;
    };

    return calcDelta;
  })();

  maybeShowPopup() {
    this.element.classList.toggle("d-none", !this.showPopup());
  }

  showPopup() {
    if (!canAccessLocalStorage()) return;

    // Show the popup if the user has not dismissed it
    if (!this.dismissedAt()) return true;

    // Show the popup only if the user has not dismissed it today
    return !this.datesAreOnSameDay(new Date(), this.dismissedAt());
  }

  dismissedAt() {
    const dismissedAtString = localStorage.getItem(
      this.resourceKey("dismissed_at")
    );
    return dismissedAtString ? new Date(dismissedAtString) : null;
  }

  onClose() {
    localStorage.setItem(
      this.resourceKey("dismissed_at"),
      new Date().toISOString()
    );
  }

  resourceKey(key) {
    return `exit_intent/${this.resourceTypeValue}/${this.resourceIdValue}.${key}`;
  }

  datesAreOnSameDay(first: Date, second: Date) {
    return first.toDateString() === second.toDateString();
  }
}
