import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];
  static values = {
    active: {
      type: Boolean,
      default: false,
    },
    on: String,
    off: String,
  };

  declare activeValue: boolean;
  declare onValue: string;
  declare offValue: string;
  declare buttonTargets: Array<HTMLButtonElement>;
  declare btnClasses: Array<string>;

  connect() {
    this.bindMethods();
  }

  disconnect() {
    this.unbindMethods();
  }

  bindMethods() {
    window.addEventListener("liked", this.update.bind(this));
    window.addEventListener("unliked", this.update.bind(this));
  }

  unbindMethods() {
    window.removeEventListener("liked", this.update.bind(this));
    window.removeEventListener("unliked", this.update.bind(this));
  }

  update() {
    this.activeValue = !this.activeValue;
    this.buttonTargets.forEach((target: HTMLButtonElement) => {
      this.updateButton(target);
      const button = this.getButtonText(target);
      button.textContent = this.activeValue ? this.onValue : this.offValue;
    });
  }

  getButtonText(target: HTMLSpanElement) {
    return target.querySelector("span");
  }

  updateButton(el: HTMLButtonElement) {
    el.dataset.status = `${this.activeValue}`;
  }
}
