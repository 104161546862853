import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    stripePublishableKey: String,
    paymentCurrency: String,
    paymentAmount: Number,
    savedCardForm: Boolean,
  };

  connect() {
    const stripe = window.Stripe(this.stripePublishableKeyValue);

    // payment request button
    const stripePaymentRequest = this.setupStripePaymentRequest(stripe);
    const stripeElements = this.setupStripeElements(stripe);
    this.createStripePaymentRequestButton(stripePaymentRequest, stripeElements);

    this.registerStripePaymentRequestHandler(stripe, stripePaymentRequest);
  }

  setupStripePaymentRequest(stripe) {
    return stripe.paymentRequest({
      country: "GB",
      currency: this.paymentCurrencyValue.toLowerCase(),
      total: {
        label: "Total",
        amount: this.paymentAmountValue,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });
  }

  setupStripeElements(stripe) {
    return stripe.elements({
      fonts: [
        {
          cssSrc: "https://fonts.googleapis.com/css?family=Roboto:400",
        },
      ],
    });
  }

  createStripePaymentRequestButton(stripePaymentRequest, stripeElements) {
    const button = stripeElements.create("paymentRequestButton", {
      paymentRequest: stripePaymentRequest,
    });

    stripePaymentRequest
      .canMakePayment()
      .then((result) => {
        if (result) {
          button.mount("#payment-request-button");
          this.showStripePaymentRequestButton(button);
        } else {
          this.hideStripePaymentRequestButton();
        }
        return;
      })
      .catch(() => undefined);
  }

  // Event handler

  registerStripePaymentRequestHandler(stripe, stripePaymentRequest) {
    const stripeClientSecret = this.stripeClientSecret();

    const paymentForm = this.selectPaymentForm();
    const paymentFormSubmitButton = this.selectPaymentFormSubmitButton();

    stripePaymentRequest.on("paymentmethod", (event) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      window.disableModal();

      paymentFormSubmitButton.setAttribute("disabled", "disabled");
      paymentFormSubmitButton.setAttribute(
        "value",
        "Please wait, payment in progress"
      );

      let paymentTermsCheckbox = document.getElementById("payment_terms");
      if (!paymentTermsCheckbox) {
        paymentTermsCheckbox = document.getElementById(
          "gift_payment_attributes_terms"
        );
      }
      const isPaymentTermsChecked = paymentTermsCheckbox?.checked;

      stripe
        .confirmCardPayment(
          stripeClientSecret,
          { payment_method: event.paymentMethod.id },
          { handleActions: false }
        )
        .then((confirmResult) => {
          if (confirmResult.error) {
            event.complete("fail");
            this.addErrorsToPaymentForm();
          } else {
            event.complete("success");
            if (confirmResult.paymentIntent.status === "requires_action") {
              //eslint-disable-next-line
              stripe
                .confirmCardPayment(stripeClientSecret)
                .then((result) => {
                  if (result.error) {
                    this.addErrorsToPaymentForm();
                    window.enableModal();
                  } else {
                    // The payment has been processed!
                    if (result.paymentIntent.status === "succeeded") {
                      this.paymentRequestButtonSuccessfullyProcessed(
                        paymentForm,
                        result,
                        paymentTermsCheckbox,
                        isPaymentTermsChecked
                      );
                    }
                  }
                  return;
                })
                .catch(() => undefined);
            } else {
              this.paymentRequestButtonSuccessfullyProcessed(
                paymentForm,
                confirmResult,
                paymentTermsCheckbox,
                isPaymentTermsChecked
              );
            }
          }
          return;
        })
        .catch(() => undefined);
    });
  }

  paymentRequestButtonSuccessfullyProcessed(
    form,
    result,
    termsCheckbox,
    isTermsChecked
  ) {
    // ensure terms checkbox is checked before submitting the form to the backend
    if (termsCheckbox && !isTermsChecked) {
      termsCheckbox.click();
    }

    this.setStripeIntentId(result.paymentIntent.id);

    // Remove event to prevent a loop
    // No simple non-jquery alternative to removing anonymous eventlisteners
    $(form).off("submit");

    Rails.fire(form, "submit");
  }

  addErrorsToPaymentForm() {
    const errors = this.selectPaymentFormErrors();
    errors.textContent = "";
    errors.classList.add("form-error-field");
    errors.classList.add("card-error-message");
    errors.append(
      "We’re sorry but your payment was unsuccessful, please check your card details or contact your bank"
    );

    // Do not need to manually validate name for Stripe Payment Request Button

    const submitButton = this.selectPaymentFormSubmitButton();
    submitButton.removeAttribute("disabled");
    submitButton.setAttribute(
      "value",
      submitButton.getAttribute("data-original-title")
    );
  }

  // Styling

  showStripePaymentRequestButton() {
    document.getElementById("payment-request-button").style.display = "block";
    document.getElementById("quick-pay-terms").style.display = "block";
    document.getElementById("quick-pay-divider").style.display = "flex";
  }

  hideStripePaymentRequestButton() {
    document.getElementById("payment-request-button").style.display = "none";
  }

  // Value selectors

  stripeClientSecret() {
    let selector = ".js-stripe-intent-secret";
    if (this.savedCardFormValue) selector = selector.concat("__saved");

    return document.querySelector(selector).getAttribute("value");
  }

  setStripeIntentId(stripeIntentId) {
    let selector = ".js-stripe-intent-id";
    if (this.savedCardFormValue) selector = selector.concat("__saved");

    document.querySelector(selector).setAttribute("value", stripeIntentId);
  }

  // Element selectors

  selectPaymentFormSubmitButton() {
    let selector = ".js-stripe-submit";
    if (this.savedCardFormValue) selector = selector.concat("__saved");

    return document.querySelector(selector);
  }

  selectPaymentForm() {
    let selector = "payment-form";
    if (this.savedCardFormValue) selector = selector.concat("__saved");

    return document.getElementById(selector);
  }

  selectPaymentFormErrors() {
    let selector = "card-errors";
    if (this.savedCardFormValue) selector = selector.concat("__saved");

    return document.getElementById(selector);
  }
}
