const SELECTOR_BTN = '.js-accordion-btn';
const SELECTOR_CONTAINER = '.bm-accordion__item';
const SELECTOR_SHADOW = 'bm-accordion--shadow';

export default function setupAccordionShadowAndTracking() {
  $(document).on('click', SELECTOR_BTN, e => {
    setTimeout(() => {
      if (e.target.type === 'button') {
        if ($(e.target).hasClass('collapsed')) {
          sendSegmentPresales(
            e.target.getAttribute('question-name'),
            'minimise'
          );
        } else {
          sendSegmentPresales(e.target.getAttribute('question-name'), 'expand');
        }
      } else {
        if ($(e.target.parentElement).hasClass('collapsed')) {
          sendSegmentPresales(
            e.target.parentElement.getAttribute('question-name'),
            'minimise'
          );
        } else {
          sendSegmentPresales(
            e.target.parentElement.getAttribute('question-name'),
            'expand'
          );
        }
      }
      if (!$(e.target).hasClass('collapsed')) {
        $(`.${SELECTOR_SHADOW}`).removeClass(SELECTOR_SHADOW);
        $(e.target)
          .closest(SELECTOR_CONTAINER)
          .addClass(SELECTOR_SHADOW);
      } else {
        $(e.target)
          .closest(SELECTOR_CONTAINER)
          .removeClass(SELECTOR_SHADOW)
          .addClass('collapsed');
      }
      $(e.target).focus();

      function sendSegmentPresales(questionName, clickType) {
        const url = '/track_faq_interaction';
        const data = {
          question_name: questionName,
          click_type: clickType
        };

        $.ajax({
          type: 'POST',
          url: url,
          data: data,
          dataType: 'json'
        });
      }
    }, 200);
  });
}
