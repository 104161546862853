export default function setupPaymentValidation($form) {
  clearInvalidFields($form);

  if ($form.hasClass('js-validate-user-fields')) {
    nameIsValid($form.find('.js-buyer-first-name'), 'First name');
    nameIsValid($form.find('.js-buyer-last-name'), 'Last name');
    emailIsValid($form.find('.js-buyer-email'));
  }

  const termsCheckbox = $('input[type="checkbox"][required]');

  // If the checkbox is not there, a hidden field will replace it

  if (termsCheckbox.length && !termsCheckbox[0].checked) {
    renderValidationError($form, termsCheckbox[0]);
    changeValidationsOnCheck($form, termsCheckbox[0]);
  }
}

function nameIsValid($input, prefix) {
  if (!$input.val().length) {
    renderError($input, `${prefix} can't be blank`);
  } else {
    return true;
  }
}

function emailIsValid($inputField) {
  const email = $inputField.val();
  if (email.length === 0) {
    renderError($inputField, "Email address can't be blank");
  } else if (!/^[^@\s,]+@[-\w.]+\.[a-z.]{2,}$/i.test(email)) {
    renderError($inputField, "Email address must be a valid email address");
  } else {
    return true;
  }
}

function renderError($inputField, msg) {
  const $errorEl = $(`<div class='invalid-feedback mt-2'>${msg}</div>`);
  $inputField
    .addClass('is-invalid')
    .closest('.form-group')
    .append($errorEl);
}

function clearInvalidFields($form) {
  const $invalidFields = $form.find('.is-invalid');

  $invalidFields.each((ind, el) => {
    $(el)
      .removeClass('is-invalid')
      .siblings('.invalid-feedback')
      .remove();
  });
}

function renderValidationError($form, termsCheckbox) {
  $form
    .find('.terms-invalid-feedback')
    .text(
      'Please review and agree to the terms and privacy policy to make a purchase.'
    )
    .show();

  termsCheckbox.classList.add('is-invalid');
  termsCheckbox.focus();
}

function changeValidationsOnCheck($form, termsCheckbox) {
  $(termsCheckbox).one('change', e => {
    if (e.target.checked) {
      $form
        .find('.terms-invalid-feedback')
        .text('')
        .hide();

      e.target.classList.remove('is-invalid');
    }
  });
}
