import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (!window.MutationObserver) return;
    this.onMutation = this.onMutation.bind(this);
    this.observer = new MutationObserver(this.onMutation);
    this.observer.observe(this.element, { childList: true });
  }

  onMutation() {
    const cookieBotBanner = document.getElementById("CybotCookiebotDialog");
    if (!cookieBotBanner) return;

    this.observer.observe(cookieBotBanner, { attributes: true });
    const cookieBotBannerVisible = cookieBotBanner.style.display != "none";
    this.element.classList.toggle("scroll-disabled", cookieBotBannerVisible);
  }
}
