const SELECTOR = '.js-scroll-x-by';

const ATTR_VIEWPORT = 'data-scroll-x-by-viewport';

/**
 * Scrolls the elements with the SELECTOR of a fraction
 * of the viewport, defined in their ATTR_VIEWPORT attribute
 */
export default function setupScrollXBy() {
  $(SELECTOR).each(function(index, el) {
    el.scrollLeft =
      parseFloat(el.getAttribute(ATTR_VIEWPORT) || '0') * window.innerWidth;
  });
}
