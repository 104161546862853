const SELECTOR_FORM = '.js-offer-select-form';
const SELECTOR_FORM_INPUTS = '.js-offer-select-form input.form-check-input';
const SELECTOR_TOGGLE_CONTENT = '.js-toggle-overview-content';
const SELECTOR_PAY_BUTTON = '.js-update-payment-amount';
const SELECTOR_MODAL = '.bm-modal';
const SELECTOR_MODAL_CLOSE = '.bm-modal__close';

const CLASS_MODAL_CLOSE_NO_TRANSITION = 'bm-close-icon--no-transition';
const CLASS_MODAL_CLOSE_COLOR_INVERTED = 'bm-modal--close-btn-inverted';

export default function setupOfferSelect() {
  setupOfferSelectInit();
}

function setupOfferSelectInit() {
  const $form = $(SELECTOR_FORM);

  $(SELECTOR_FORM_INPUTS).each((ind, input) => {
    input.addEventListener('change', ({ target }) => {
      disableCurrentModal();
      updatePaymentIntent(target.value);
    });
  });

  function updatePaymentIntent(selection) {
    const course_id = $form.attr('data-course-id');

    let data = {
      country_code: $form.attr('data-country-code'),
      course_id: course_id,
      voucher_code: $form.attr('data-voucher-code'),
      campaign_id: $form.attr('data-campaign-id'),
      gift_data: $form.attr('data-gift-params'),
      payable_type: $form.attr('data-payable-type'),
      selected_offer: selection
    };

    const payable_type = $form.attr('data-payable-type');

    if (payable_type === 'Gift' && !course_id) {
      data.generic = true;
    }

    let url = `/payments/${course_id}/offer_select`;

    // if (payable_type === 'CourseAccess') {
    //   url = `/payments/${course_id}/offer_select`;
    // } else if (payable_type === 'Gift') {
    //   url = `/gifts/payments/offer_select`;
    // }

    $.ajax({
      type: 'POST',
      url: url,
      data: data,
      dataType: 'json',
      success: () => {
        updateModalElements(selection);
        enableCurrentModal();
      }
    });
  }
}

function updateModalElements(selection) {
  // Toggle content visiblity
  $(SELECTOR_TOGGLE_CONTENT).toggleClass('d-none');

  // Update submit button price
  const updatedPrice = $(`.js-${selection}-price`).text();
  $(SELECTOR_PAY_BUTTON).text(`Pay ${updatedPrice} now`);

  // Toggle modal close button colour
  const $closeBtn = $(SELECTOR_MODAL_CLOSE);

  // Adding a class to remove the transition so the switch happens
  // instantly. Using a timeout to ensure there's no transition
  $closeBtn.addClass(CLASS_MODAL_CLOSE_NO_TRANSITION);
  $(SELECTOR_MODAL).toggleClass(CLASS_MODAL_CLOSE_COLOR_INVERTED);

  setTimeout(() => {
    $closeBtn.removeClass(CLASS_MODAL_CLOSE_NO_TRANSITION);
  }, 100);
}

function disableCurrentModal() {
  $(SELECTOR_FORM_INPUTS).attr('disabled', 'true');
  $(SELECTOR_PAY_BUTTON).attr('disabled', 'true');

  window.disableModal();
}

function enableCurrentModal() {
  $(SELECTOR_FORM_INPUTS).removeAttr('disabled', 'true');
  $(SELECTOR_PAY_BUTTON).removeAttr('disabled', 'true');

  window.enableModal();
}
