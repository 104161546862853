const SELECTOR_SLIDER = '.js-slider';
const SELECTOR_PAGINATION = '.js-slider-pagination';
const SELECTOR_PAGINATION_ACTIVE = 'bm-slider--active';
const SELECTOR_PAGINATION_BTNS = '.js-slider-pagination-btn';

export default function setupSliderPagination() {
  $(document).on('turbolinks:load', () => {
    mobileSliderInit();
  });
  mobileSliderInit();

  $(document).on('turbolinks:before-cache', () => {
    $(SELECTOR_PAGINATION).html('');
  });

  function mobileSliderInit() {
    if ($(SELECTOR_SLIDER).length) {
      const slides = $(SELECTOR_SLIDER)
        .children('li')
        .toArray();

      renderPaginationBtns(slides);

      // Check if slide is more than 60% in view without
      // listening to the scroll event
      const observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            const indexOfSlide = $(entries[0].target).index();
            // Remove/add classes
            setActivePaginationBtn(indexOfSlide);
          }
        },
        { threshold: [0.6] }
      );
      $(slides).each((ind, el) => {
        observer.observe(el);
      });

      // Scroll to slide on pagination click
      $(SELECTOR_PAGINATION_BTNS).on('click', e => {
        const indexOfBtn = $(e.target).index();
        const targetSlideOffset = $(SELECTOR_SLIDER).children()[indexOfBtn]
          .offsetLeft;

        $(SELECTOR_SLIDER)[0].scrollTo({
          top: 0,
          left: targetSlideOffset,
          behavior: 'smooth'
        });
      });
    }
  }
}

function renderPaginationBtns(slides) {
  $(slides).each(() => {
    $(SELECTOR_PAGINATION).append(
      '<div class="bm-slider__pagination js-slider-pagination-btn" role="button"></div>'
    );
  });
}

function setActivePaginationBtn(indexOfSlide) {
  // Remove any active classes
  $(SELECTOR_PAGINATION_BTNS).removeClass(SELECTOR_PAGINATION_ACTIVE);

  // Add active class
  const paginationBtn = $(SELECTOR_PAGINATION)
    .children()
    .eq(indexOfSlide);
  $(paginationBtn).addClass(SELECTOR_PAGINATION_ACTIVE);
}
