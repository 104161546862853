const SELECTOR_MAIN_MODAL = '.bm-modal';

export function setupDeviseHandler() {
  // Rails UJS enables button after form success
  // but before turbolinks visits a new page,
  // so disable in between & enable before-cache
  $(document).on('ajax:send', 'form[data-remote=true]', e => {
    const $form = $(e.target);
    const $button = $form.find('[data-disable-with]');
    if (!$button.length) return;

    $form.on('ajax:complete', () => {
      // Use setTimeout to prevent race-condition when Rails re-enables the button
      setTimeout(() => {
        $button.each((ind, el) => {
          window.Rails.disableElement(el);
        });
      }, 0);
    });

    // Prevent button from being cached in disabled state
    $(document).one('turbolinks:before-cache', () => {
      $button.each((ind, el) => {
        window.Rails.enableElement(el);
      });
    });
  });

  // Close modal & backdrop when navigating away
  $(document).on('turbolinks:before-cache', () => {
    $(SELECTOR_MAIN_MODAL).modal('hide');
  });

  // After redeeming a gift, before-cache leaves backdrop,
  // so using before-visit here
  $(document).on('turbolinks:before-visit', () => {
    $(SELECTOR_MAIN_MODAL).modal('hide');
  });

  // When closing modal, check for other actions such as...
  $(document).on('hide.bs.modal', '.modal', e => {
    modalCheckBeforeClosing(e);
  });
  $(document).on('click', '.js-close-modal', e => {
    modalCheckBeforeClosing(e);
  });

  function modalCheckBeforeClosing(e) {
    const $modal = $(SELECTOR_MAIN_MODAL);

    if ($modal.attr('data-disable-modal')) {
      // ... completely disable the modal...
      e.preventDefault();
      e.stopImmediatePropagation();
    } else if ($modal.attr('data-confirmation')) {
      // ... if the page needs reloading...
      e.preventDefault();
      e.stopImmediatePropagation();
      $modal.find('.btn.js-close-modal').addClass('disabled');
      $modal.removeAttr('data-confirmation');
      //eslint-disable-next-line
      Turbolinks.visit(location.toString());
    } else if ($modal.attr('data-back-to-payment')) {
      // ...or if the previous form should be shown
      e.preventDefault();
      e.stopImmediatePropagation();
      $modal.find('.js-modal-content-voucher').hide();
      $modal.find('.js-modal-content-main').show();
      $modal.removeAttr('data-back-to-payment');
    } else {
      $modal.removeClass('bm-modal--close-btn-inverted');
      $modal[0].dispatchEvent(new Event("close"));

      // Remove overflow: hidden on html element to allow page scrolling again
      $('html').removeClass('modal-open');
    }
  }
}

// Global function for multiple lightbox content elements
// If the next content is already populated, prevent loading and show it instead
export function switchModalContent(target, currentContent, nextContent) {
  $(target).on('click', e => {
    if ($(nextContent).children().length) {
      e.preventDefault();
      e.stopImmediatePropagation();
      $(currentContent).hide();
      $(nextContent).show();

      if (nextContent === '.js-modal-content-voucher') {
        $('.bm-modal').attr('data-back-to-payment', 'true');
      } else {
        $('.bm-modal').removeAttr('data-back-to-payment');
      }
    }
  });
}

// Disable & enable modal for payments
export function disableModal() {
  $('.bm-modal').attr('data-disable-modal', 'true');
}
export function enableModal() {
  $('.bm-modal').removeAttr('data-disable-modal');
}

export function invertModalCloseButton() {
  $('.bm-modal').addClass('bm-modal--close-btn-inverted');
}

export function showModal() {
  const $modal = $(SELECTOR_MAIN_MODAL);

  if (!$modal.hasClass('show')) {
    $modal.modal('show');
    $modal.on('transitionend', focusFirstModalInput);

    // This function is declared inside so it can be removed once completed
    /* eslint-disable no-inner-declarations */
    function focusFirstModalInput() {
      const $modal = $(SELECTOR_MAIN_MODAL);
      const $elToFocus = $modal.find('[autofocus]');

      if (
        typeof event != 'undefined' &&
        event.propertyName === 'transform' &&
        $elToFocus.length
      ) {
        $elToFocus.eq(0).focus();
        $modal.off('transitionend', focusFirstModalInput);
      }
    }
    /* eslint-enable no-inner-declarations */
  } else {
    const $elToFocus = $modal.find('[autofocus]');
    if ($elToFocus.length) {
      $elToFocus.eq(0).focus();
    }
  }

  $modal.scrollTop(0);

  // Set overflow hidden on html element to prevent scrolling
  // This is will hidden page content under the above the fold field
  $('html').addClass('modal-open');
}
