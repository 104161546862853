import { Controller } from "@hotwired/stimulus";

type ProductType = "Subscription" | "Course";

export default class extends Controller {
  static values = {
    type: String,
    slug: {
      type: String,
      default: "product",
    },
  };

  declare typeValue: ProductType;
  declare slugValue: string;

  private get productData() {
    return {
      subscription: {
        event_type: "product_added",
        variant: "Buy Subscription",
        generic: false,
        subscription_gift: false,
        product_type: "subscription",
      },
      course: {
        course_slug: this.slugValue,
        event_type: "product_added",
        variant: "Buy Course",
        generic: false,
        subscription_gift: false,
        product_type: "course",
      },
    };
  }

  connect() {
    this.sendData();
  }

  sendData() {
    $.ajax({
      type: "POST",
      url: `/course/${this.slugValue}/segment`,
      data: this.productData[this.typeValue],
      dataType: "script",
    });
  }
}
