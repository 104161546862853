import payWithSavedCard from './pay_with_saved_card';
import updateCard from './update_card';

/* eslint-disable no-inner-declarations */
function cardForm() {
  const $form = $('.js-stripe-form');
  if ($form.length) {
    const stripe = window.Stripe($form.attr('data-stripe-publishable-key'));

    var elements = stripe.elements({
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css?family=Roboto:400'
        }
      ]
    });

    // Custom styling can be passed to options when creating an Element.
    var style = {
      base: {
        iconColor: '#555',
        color: '#555',
        fontWeight: 400,
        fontFamily: '"Roboto", sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',

        '::placeholder': {
          color: '#999'
        },
        ':-webkit-autofill': {
          color: '#555'
        }
      },
      invalid: {
        iconColor: 'red',
        color: '#b00020'
      }
    };

    // TO DO: Make the init code reusable for other card forms (will revisit when working on billing page)

    // Create the elements
    var cardNumber = elements.create('cardNumber', {
      style: style,
      placeholder: 'Card number'
    });
    var cardExpiry = elements.create('cardExpiry', {
      style: style,
      placeholder: 'MM/YY'
    });
    var cardCvc = elements.create('cardCvc', {
      style: style,
      placeholder: 'CVC'
    });
    var cardPostal = elements.create('postalCode', {
      style: style,
      placeholder: ''
    });

    // Handle real-time validation errors
    [
      [cardNumber, "#card-number"],
      [cardExpiry, "#card-expiry"],
      [cardCvc, "#card-cvc"],
      [cardPostal, "#card-postcode"],
    ].forEach((item) => {
      const element = item[0];
      const domID = item[1];
      element.mount(domID);

      element.on("change", (event) => {
        const $displayError = $(domID).siblings(".form-error-field");

        if (event.error) {
          let message = event.error.message;

          if (addressCountry() == "US") {
            message = message.replace(/postal code/i, "zip code");
          }

          $displayError.text(message);
        } else {
          $displayError.removeClass("card-error-message");
          $displayError.empty();
        }
      });
    });

    // Submit form
    var submitButton = document.querySelector('.js-stripe-submit');
    var form = document.getElementById('payment-form');

    if (form) {
      $(form).on('submit', event => {
        event.preventDefault();
        event.stopImmediatePropagation();

        if (userDetailsValid()) {
          submitStripeForm(event);
        }
      });
    }

    function submitStripeForm() {
      window.disableModal();

      submitButton.setAttribute('disabled', 'disabled');
      submitButton.setAttribute('value', 'Please wait, payment in progress');

      stripe
        .confirmCardPayment(stripeIntentClientSecret(), {
          payment_method: {
            card: cardNumber,
            billing_details: {
              name: $(".js-name-on-card").val(),
              email: $(".js-buyer-email").val(),
              address: {
                // Note: Postcode (Zip code) is automatically populated using a Stripe elements postalCode below
                line1: $("[data-address-line1]").val(),
                country: addressCountry(),
              },
            },
            metadata: {
              buyer_first_name: $('.js-buyer-first-name').val(),
              buyer_last_name: $('.js-buyer-last-name').val(),
              buyer_email: $('.js-buyer-email').val()
            }
          },
          setup_future_usage: 'off_session'
        })
        .then(function(result) {
          if (result.error) {
            addErrors(true);
            window.enableModal();
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              $('.js-stripe-intent-id').val(result.paymentIntent.id);
              // Remove event to prevent a loop
              $(form).off('submit');
              //eslint-disable-next-line
              Rails.fire(form, 'submit');
            }
          }
          return null;
        })
        .catch(function() {});
    }
  }

  function stripeIntentClientSecret() {
    return $(".js-stripe-intent-secret").val();
  }

  function addErrors(checkName) {
    const errors = $('#card-errors');

    errors
      .addClass('form-error-field')
      .addClass('card-error-message')
      .empty()
      .append(
        'We’re sorry but your payment was unsuccessful, please check your card details or contact your bank'
      );

    if (checkName) {
      validateName();
    }

    submitButton.removeAttribute('disabled');
    submitButton.setAttribute(
      'value',
      $(submitButton).attr("data-original-title")
    );

    // If survey input is on checkout, it needs to shift up
    //  to avoid overlap with error message box.
    const survey_input = $("#survey-input");
    if (survey_input) {
      survey_input.addClass(
        "bm-checkout__survey--position-above-payment-errors"
      );
    }
  }

  // Validation for cardholder name field
  function validateName() {
    $('.invalid-feedback').text('');
    const nameField = $('.js-name-on-card');
    const name = $(nameField).val();

    if (/^[a-zA-Z\s]+$/.test(name)) {
      $(nameField).removeClass('is-invalid');
      $(nameField)
        .next()
        .text('');
      return true;
    } else if (name.length === 0) {
      updateNameError("Name can't be blank");
    } else {
      updateNameError('Name can only contain letters');
    }

    function updateNameError(msg) {
      $(nameField).after(`<div class='invalid-feedback'>${msg}</div>`);
      $(nameField).addClass('is-invalid');
      $('.invalid-feedback').show();
    }
  }

  function addressCountry() {
    return $("[data-address-country]").data("address-country");
  }

  function validateAddressFields() {
    if (addressCountry() !== "US") {
      return true;
    }

    const $line1 = $("[data-address-line1");
    const line1Label = $line1.siblings("label").text();
    const $line1Error = $line1.siblings(".form-error-field");

    const line1Value = $line1.val() as string;

    if (line1Value.trim() == "") {
      $line1.addClass("is-invalid");
      $line1Error.text(`${line1Label} can't be blank`);
    } else {
      $line1.removeClass("is-invalid");
      $line1Error.empty();
    }
  }

  function userDetailsValid() {
    window.setupPaymentValidation($form);

    validateAddressFields();

    if (!$form.find('.is-invalid').length) {
      return true;
    } else {
      return false;
    }
  }

  payWithSavedCard();

  updateCard();
}

export default function setupCardForm() {
  cardForm();
}
