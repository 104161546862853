import { Controller } from "@hotwired/stimulus";

const FORM_ELEMENTS = ["INPUT", "SELECT", "TEXTAREA"];

export default class extends Controller {
  connect() {
    this.handleKeyDown = this.handleKeyDown.bind(this);
    document.addEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown(event) {
    if (event.key !== "Escape") return;
    if (!this.element.contains(event.target)) return;

    if (!FORM_ELEMENTS.includes(event.target.tagName)) {
      $(this.element).modal("hide");
    }
  }

  close() {
    this.element.style.display = "none";
    document.querySelector(".modal-backdrop").remove();
  }
}
