const SELECTOR = '.js-password-toggle';

export default function setupTogglePassword() {
  $(document).on('click', SELECTOR, ({ target }) => {
    toggle(target.previousSibling, target);
  });

  $(document).on('keydown', SELECTOR, ({ keyCode, target }) => {
    if (keyCode === 13 || keyCode === 32) {
      event.preventDefault();
      toggle(target.previousSibling, target);
    }
  });

  function toggle(input, toggleButton) {
    if (input.type === 'password') {
      input.type = 'text';
      toggleButton.setAttribute('aria-expanded', 'true');
      toggleButton.setAttribute('aria-label', 'hide password');
    } else {
      input.type = 'password';
      toggleButton.setAttribute('aria-expanded', 'false');
      toggleButton.setAttribute('aria-label', 'show password');
    }
  }
}
