import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["terms"];

  toggle(event) {
    const checked = event.currentTarget.checked;
    this.termsTarget.classList.toggle("d-none", !checked);
  }
}
