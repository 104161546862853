import Macy from 'macy';
const SELECTOR = '.js-masonry';

export default function setupMasonry() {
  var macyInstance;

  init();
  $(document).on('turbolinks:load', () => {
    init();
  });

  $(document).on('turbolinks:before-cache', () => {
    if (macyInstance) {
      macyInstance.remove();
      macyInstance = null;
    }
  });

  function init() {
    if (document.querySelector(SELECTOR)) {
      macyInstance = Macy({
        // See below for all available options.
        container: SELECTOR,
        columns: 4,
        trueOrder: true,
        margin: {
          x: 0, // Horizontal spacing is already dealt with by Bootstrap's columns
          y: 0,
        },
        breakAt: {
          992: 3,
          768: 2,
          576: 4 // Back to 4 as the content gets scrollable
        }
      });

      // A quick fix for https://github.com/bigbite/macy.js/issues/81
      // On first page load, some vertical spacing collapses.
      // We need to recalculate the instance once everything has loaded,
      // but all macy events fire too early, so triggering an event per image
      // load.
      macyInstance.runOnImageLoad(function() {
        macyInstance.recalculate(true, true);
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
      }, true);
    }
  }
}
