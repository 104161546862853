const BUY_COURSE_SELECTOR = ".js-buy-course";
const BUY_SUBSCRIPTION_SELECTOR = ".js-buy-subscription";
const GIFT_COURSE_SELECTOR = ".js-gift-course";
const GENERIC_GIFT_SELECTOR = ".js-generic-gift";
const SUBSCRIPTION_GIFT_SELECTOR = ".js-subscription-gift";
const PRICE_DETAILS_SELECTOR = ".js-price-details";

export default function productAdded() {
  productAddedInit();
  $(document).on("turbolinks:load", productAddedInit);
}

function productAddedInit() {
  const buyCourseActions = document.querySelectorAll(BUY_COURSE_SELECTOR);
  const buySubscriptionActions = document.querySelectorAll(
    BUY_SUBSCRIPTION_SELECTOR
  );
  const giftCourseActions = document.querySelectorAll(GIFT_COURSE_SELECTOR);
  const genericGiftActions = document.querySelectorAll(GENERIC_GIFT_SELECTOR);
  const subscriptionGiftActions = document.querySelectorAll(
    SUBSCRIPTION_GIFT_SELECTOR
  );

  buyCourseActions.forEach(function (cta) {
    $(cta).on("click", () => {
      const courseSlug = cta.dataset.courseSlug;
      sendData("Buy Course", courseSlug, false, false, "course");
    });
  });

  giftCourseActions.forEach(function (cta) {
    $(cta).on("click", () => {
      const courseSlug = cta.dataset.courseSlug;
      sendData("Gift Course", courseSlug, false, false, "gift_course");
    });
  });

  genericGiftActions.forEach(function (cta) {
    $(cta).on("click", () => {
      sendData("Gift Course", null, true, false, "gift_generic");
    });
  });

  buySubscriptionActions.forEach(function (cta) {
    $(cta).on("click", () => {
      sendData("Buy Subscription", null, false, true, "subscription");
    });
  });

  subscriptionGiftActions.forEach(function (cta) {
    $(cta).on("click", () => {
      sendData("Gift Subscription", null, false, true, "gift_subscription");
    });
  });

  function sendData(
    variant,
    courseSlug = null,
    generic = false,
    subscriptionGift = false,
    product_type = null
  ) {
    if (
      courseSlug === null &&
      (generic === false || subscriptionGift === false)
    ) {
      courseSlug = document.querySelector("body").dataset.courseSlug;
    }

    let data;
    const priceDetails = document.querySelector(PRICE_DETAILS_SELECTOR);

    if (priceDetails) {
      const feeId = priceDetails.dataset.feeId;
      const voucherCode = priceDetails.dataset.voucherCode;
      const discountCents = priceDetails.dataset.discountCents;

      data = {
        course_slug: courseSlug,
        event_type: "product_added",
        variant: variant,
        fee_id: feeId,
        voucher_code: voucherCode,
        discount_cents: discountCents,
        generic: generic,
        subscription_gift: subscriptionGift,
        product_type: product_type,
      };
    } else {
      data = {
        course_slug: courseSlug,
        event_type: "product_added",
        variant: variant,
        generic: generic,
        subscription_gift: subscriptionGift,
        product_type: product_type,
      };
    }

    const url = `/course/${courseSlug}/segment`;

    $.ajax({
      type: "POST",
      url: url,
      data: data,
      dataType: "script",
    });
  }
}
