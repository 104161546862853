import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];

  declare formTargets: Array<HTMLFormElement>;

  onTrigger(event: Event) {
    this.formTargets.forEach((form) => {
      const isValidForm = form.reportValidity();

      if (!isValidForm) {
        event.preventDefault();
      } else {
        form.requestSubmit();
      }
    });
  }
}
