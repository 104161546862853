// should be in vendor, but library throws jQuery undefined errors
import "@nathanvda/cocoon";
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

import "./sentry";

import setupCardForm from "../javascripts/stripe/stripe";
import setupVideo from "../javascripts/videoPlayer";
import setupMobileNav from "../javascripts/setup_mobile_nav";
import scrollToNextSection from "../javascripts/scroll_to_next_section";
import setupSliderPagination from "../javascripts/slider_pagination";
import setupLazyLoad from "../javascripts/setup_lazy_load";
import setupAccordionShadowAndTracking from "../javascripts/setup_accordion_shadow_and_tracking";
import setupLessonSlider from "../javascripts/setup_lesson_slider";
import setupUserProgress from "../javascripts/setup_user_progress";
import setupMailingList from "../javascripts/mailing_list";
import setupTogglePassword from "../javascripts/setup_toggle_password";
import setupVideoChapters from "../javascripts/videoPlayer/setup_video_chapters";
import setupScrollTo from "../javascripts/setup_scroll_to";
import setupTableCollapseScroll from "../javascripts/setup_table_collapse_scroll";
import setupPopup from "../javascripts/setup_popup";
import setupMobileStickyCta from "../javascripts/setup_mobile_sticky_cta";
import {
  setupDeviseHandler,
  switchModalContent,
  disableModal,
  enableModal,
  invertModalCloseButton,
  showModal,
} from "../javascripts/setup_devise_ujs";
import {
  setupECardCalendar,
  initECardCalendarCheck,
} from "../javascripts/setup_e_card_calendar";
import setupECardValidations from "../javascripts/setup_e_card_validations";
import setupCustomFlashBanner from "../javascripts/setup_custom_flash_banner";
import productAdded from "../javascripts/segment/product_added";
import setupUtilityJS from "../javascripts/setup_utility_js";
import setupSignUpValidation from "../javascripts/setup_sign_up_validation";
import setupPaymentValidation from "../javascripts/setup_payment_validation";
// import 'jquery.cookie-consent';
import setupOfferSelect from "../javascripts/setup_offer_select";
import setupMasonry from "../javascripts/setup_masonry";
import setupScrollXBy from "../javascripts/setup_scroll_x_by";
import setupDwellTracking from "../javascripts/setup_dwell_tracking";
import setupFileUploadValidation from "../javascripts/setup_file_upload_validation";

// Stimulus.js Setup
window.Stimulus = Application.start();
const context = require.context("./controllers", true, /\.(js|ts)$/);
window.Stimulus.load(definitionsFromContext(context));

require.context("../images", true);

$(document.documentElement).removeClass("no-js");
window.setupCardForm = setupCardForm;
window.setupUserProgress = setupUserProgress;
window.setupVideoChapters = setupVideoChapters;
window.switchModalContent = switchModalContent;
window.disableModal = disableModal;
window.enableModal = enableModal;
window.setupSignUpValidation = setupSignUpValidation;
window.setupPaymentValidation = setupPaymentValidation;
window.invertModalCloseButton = invertModalCloseButton;
window.showModal = showModal;
window.setupOfferSelect = setupOfferSelect;
window.setupDwellTracking = setupDwellTracking;
window.initECardCalendarCheck = initECardCalendarCheck;

$(document).on("turbolinks:load", () => {
  setupLazyLoad();
  setupLessonSlider();
  window.objectFitPolyfill();
  setupScrollXBy();
});

$(() => {
  setupVideo();
  setupMobileNav();
  scrollToNextSection();
  setupSliderPagination();
  setupAccordionShadowAndTracking();
  setupTogglePassword();
  setupMailingList();
  setupScrollTo();
  setupTableCollapseScroll();
  setupPopup();
  setupDeviseHandler();
  setupMobileStickyCta();
  setupECardCalendar();
  setupECardValidations();
  setupCustomFlashBanner();
  productAdded();
  setupUtilityJS();
  window.objectFitPolyfill();
  setupOfferSelect();
  setupMasonry();
  setupScrollXBy();
  setupFileUploadValidation();
  // TODO: Fix the behaviour of TinyMCE around drag'n'drop
  // setupSortable();
  // TODO: This would likely justify being in its own `cms-admin` pack
  // alongside `setupCocoonPosition` and `setupCocoonReorder`. However
  // there seems to be some discrepancies between the jQuery object available
  // in the different packs, so these have to be inside `vendor` at the moment :(

  // setupCocoonPosition();
  // setupCocoonReorder();

  // Remove focus when button links to new tab
  $(document).on(
    "click",
    ".btn[target=blank], button[data-toggle=collapse]",
    (e) => {
      $(e.target).blur();
    }
  );

  // Cookiebot banner causes unwanted turbolinks scroll
  // persistence so setting it to 'manual' here
  if ("scrollRestoration" in window.history) {
    window.history.scrollRestoration = "manual";
  }
});
