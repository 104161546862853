import { Controller } from "@hotwired/stimulus";

export default class extends Controller<HTMLElement> {
  static targets = ["content"];
  static classes = ["opening", "closing"];

  declare closingClass: string;
  declare openingClass: string;
  declare contentTarget: HTMLElement;

  toggle(event: Event) {
    requestAnimationFrame(() =>
      this.contentTarget.classList.add(this.openingClass)
    );

    this.onAnimationEnd(() =>
      this.contentTarget.classList.remove(this.openingClass)
    );

    if (this.isDetailsElementOpened()) {
      event.preventDefault();
      this.contentTarget.classList.add(this.closingClass);

      this.onAnimationEnd(() => {
        this.element.removeAttribute("open");
        this.contentTarget.classList.remove(this.closingClass);
      });
    }
  }

  isDetailsElementOpened() {
    return this.element.hasAttribute("open");
  }

  onAnimationEnd(callback) {
    this.contentTarget.addEventListener("animationend", callback, {
      once: true,
    });
  }
}
