const SELECTOR = '.js-open-popup';

export default function setupPopup() {
  popupInit();
  $(document).on('turbolinks:load', () => {
    popupInit();
  });

  function popupInit() {
    if ($(SELECTOR).length) {
      $(SELECTOR).on('click', e => {
        e.preventDefault();
        const url = $(e.target).attr('href');
        window.open(url, 'share', 'height=600,width=600');
      });
    }
  }
}
