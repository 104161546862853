// File upload validations used for cms sections
// Validations for Active Admin are in app/assets/javascripts/file_upload_validation.js

const SELECTOR = '.js-file-upload-validate';
const SELECTOR_FORM = '.js-file-upload-validate-form';

const SELECTOR_ERROR_BOUNDARY = '.js-file-upload-validate__error-boundary';
const SELECTOR_ERROR_MESSAGE = '.invalid-feedback';
const SELECTOR_ADD_DESCRIPTION = '.js-file-upload-validate__add-description';

const CLASS_INVALID = 'is-invalid';

const ERROR_POSITION = 'beforeend';

// 700Kb
const MAX_SIZE = 0.7 * 1024 * 1024;

export default function setupFileUploadValidation() {
  $(document).on('submit', SELECTOR_FORM, event => {
    validateOnSubmit(event.target);
  });

  // Prepare the rendering of the validation errors
  $(document).on('change', SELECTOR, event => {
    clearError(event.target);
  });

  // Validate on the capture phase so we can stop the propagation
  // and prevent any further behaviour from happening
  $(document).on('change', SELECTOR, event => {
    const field = event.target;
    const file = field.files[0];

    if (file && file.size > MAX_SIZE) {
      displayError(
        field,
        `Please pick a file less than ${formatFileSize(MAX_SIZE)}.`
      );
      event.preventDefault();
    } else {
      clearError(field);
    }
  });
}

function validateOnSubmit(form) {
  const fileUploadInputs = Array.from(form.querySelectorAll(SELECTOR));

  const fieldsAreValid = fileUploadInputs.some(input =>
    input.classList.contains(CLASS_INVALID)
  );

  if (fieldsAreValid) {
    event.preventDefault();

    $(`.${CLASS_INVALID}`)[0].focus();

    const submitBtn = $('form').find('input[type=submit]')[0];

    setTimeout(() => {
      submitBtn.removeAttribute('disabled');
    }, 100);
  }
}

function clearError(field) {
  const boundary = field.closest(SELECTOR_ERROR_BOUNDARY);
  if (!boundary) return;

  const errorMessage = boundary.querySelector(SELECTOR_ERROR_MESSAGE);
  if (errorMessage) {
    errorMessage.parentElement.removeChild(errorMessage);
  }

  field.classList.remove(CLASS_INVALID);
  const description = boundary.querySelector(SELECTOR_ADD_DESCRIPTION);
  removeDOMToken(description, 'aria-describedby', errorId(field));
  removeDOMToken(field, 'aria-describedby', errorId(field));
}

function displayError(field, errorMsg) {
  clearError(field);
  const boundary = field.closest(SELECTOR_ERROR_BOUNDARY);

  boundary.insertAdjacentHTML(
    ERROR_POSITION,
    error(errorMsg, { id: errorId(field) })
  );

  field.classList.add(CLASS_INVALID);
  field.focus();

  const description = boundary.querySelector(SELECTOR_ADD_DESCRIPTION);
  prependDOMToken(description, 'aria-describedby', errorId(field));
  prependDOMToken(field, 'aria-describedby', errorId(field));
}

function removeDOMToken(element, attributeName, token) {
  if (!element.getAttribute(attributeName)) return;
  element.setAttribute(
    attributeName,
    element
      .getAttribute(attributeName)
      .replace(token, '')
      .trim()
  );
}

function prependDOMToken(element, attributeName, token) {
  element.setAttribute(
    attributeName,
    [token, element.getAttribute(attributeName)].filter(Boolean).join(' ')
  );
}

function formatFileSize(sizeInBytes) {
  let sizeString;
  let size = sizeInBytes / 1024 / 1024;
  if (size < 1) {
    size = size * 1000;
    sizeString = `${size}Kb`;
  } else {
    sizeString = `${size}Mb`;
  }

  return sizeString;
}

function errorId(field) {
  return field.id + '_error';
}

function error(message, { id }) {
  return `<p id="${id}" class="invalid-feedback d-block mb-2 mt-0" aria-live="assertive">${message}</p>`;
}
