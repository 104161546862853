import { Controller } from "@hotwired/stimulus";
import { scrollToElementWithOffset } from "../../javascripts/utils";

export default class extends Controller<HTMLDivElement> {
  static values = {
    currentQuestion: Number,
  };
  static targets = ["question", "endPanel"];

  declare questionTargets: Array<HTMLDivElement>;
  declare currentQuestionValue: number;
  declare current_question_index: number;
  declare endPanelTarget: HTMLDivElement;

  connect() {
    this.current_question_index = this.currentQuestionValue;
    this.displayQuestion();
  }

  next() {
    this.currentQuestion().classList.add("d-none");
    this.current_question_index += 1;

    this.displayQuestion();
  }

  displayQuestion() {
    if (this.currentQuestion()) {
      this.currentQuestion().classList.remove("d-none");
      scrollToElementWithOffset(this.currentQuestion());
    } else {
      this.endPanelTarget.classList.remove("d-none");
      scrollToElementWithOffset(this.element);
    }
  }

  currentQuestion() {
    return this.questionTargets[this.current_question_index];
  }

  hide() {
    this.element.classList.add("d-none");
  }
}
