import 'air-datepicker';
import 'air-datepicker/dist/js/i18n/datepicker.en.js';

const CLASS_SEND_LATER = 'payment_gift_attributes_delivery_schedule_send_later';

const SELECTOR_DELIVERY_DATES_CONTAINER = '.js-gift-delivery-dates';

export function setupECardCalendar() {
  $(document).on('change', SELECTOR_DELIVERY_DATES_CONTAINER, e => {
    if ($(e.target).attr('id') === CLASS_SEND_LATER) {
      initECardCalendar();
    } else {
      hideCalendar();
    }
  });
}

export function initECardCalendarCheck() {
  if ($(`#${CLASS_SEND_LATER}:checked`).length) {
    const deliveryDate = $('.js-delivery-date').val();
    initECardCalendar(deliveryDate);
  }
}

function initECardCalendar(deliveryDate) {
  console.log('Calendar init');
  $('#js-e-card-calendar').removeClass('d-none');

  const $dp = $('#js-air-datepicker').datepicker({
    language: 'en',
    minDate: new Date(),
    dateFormat: 'yyyy/mm/dd',
    onSelect: formattedDate => {
      $('#payment_gift_attributes_delivery_date').val(formattedDate);
    }
  });

  if (deliveryDate) {
    $dp.data('datepicker').selectDate(new Date(deliveryDate));
  }
}

function hideCalendar() {
  $('#js-e-card-calendar').addClass('d-none');
}
