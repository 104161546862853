import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["panel", "button"];

  changeTab(e: Event) {
    // As the tabs act as links as a fallback then we have to prevent default here
    e.preventDefault();

    // Get the current target and keep a reference to it
    const target = e.currentTarget as HTMLElement;

    // Hide all the tabs initially
    this.hideAllTabs();

    // Update the aria state of the buttons
    this.updateButtons();

    // Set the current tab button to the correct aria state
    target.setAttribute("aria-selected", "true");

    this.showTab(target);
  }

  updateButtons() {
    this.buttonTargets.forEach((button: HTMLButtonElement) => {
      button.setAttribute("aria-selected", "false");
    });
  }

  hideAllTabs() {
    this.panelTargets.forEach((tab) => {
      tab.hidden = true;
    });
  }

  showTab(target) {
    // Find out which tab section corresponds to the button we clicked
    const newTab = this.panelTargets.find(
      (tab: HTMLDivElement) =>
        tab.getAttribute("aria-labelledby") ===
        target.getAttribute("aria-controls")
    );

    // And show the panel by setting "hidden" to false
    newTab.hidden = false;
  }
}
