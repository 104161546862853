import { transitionElement } from './helpers/transition_element';

const SELECTOR_CONTAINER = '.vjs-autoplay__container';
const SELECTOR_CONTENT = '.vjs-autoplay__content';
const SELECTOR_RESTART = '.js-autoplay-restart';

const CLASS_HIDDEN = 'vjs-autoplay--hidden';
const CLASS_CLOSE_OVERLAY = 'js-close-cta-overlay';

export default function setupCtaOverlay(player) {
  // Disable looping to allow the 'ended' event to fire
  player.loop(false);

  appendCtaDialog(player);

  $(document).on('turbolinks:before-cache', () => {
    player.off('ended', ctaOverlayInit);
  });

  player.on('ended', ctaOverlayInit);

  function ctaOverlayInit() {
    animateDialogIn(player);

    setupEvents(player);
    $(`.${CLASS_CLOSE_OVERLAY}`).on('click', () => player.exitFullscreen());
  }
}

// ***** TRANSITIONS ***** \\
async function animateDialogIn(player) {
  player.addClass('vjs-big-play-button--hidden');

  await transitionElement(
    player.el().querySelector(SELECTOR_CONTAINER),
    'remove',
    CLASS_HIDDEN
  );
  await transitionElement(
    player.el().querySelector(SELECTOR_CONTENT),
    'remove',
    CLASS_HIDDEN
  );
}

async function animateDialogOut(player) {
  await transitionElement(
    player.el().querySelector(SELECTOR_CONTENT),
    'add',
    CLASS_HIDDEN
  );
  await transitionElement(
    player.el().querySelector(SELECTOR_CONTAINER),
    'add',
    CLASS_HIDDEN
  );
}

// ***** EVENTS ***** \\
// Setup restart event
function setupEvents(player) {
  $(player.el())
    .find(SELECTOR_RESTART)
    .on('click', () => {
      animateDialogOut(player);

      player.play();
      setTimeout(() => {
        player.currentTime(0);
      }, 10);
    });
}

// ***** MARKUP ***** \\
function appendCtaDialog(player) {
  // Create markup
  const $wrapper = $(
    '<div class="vjs-autoplay__container vjs-autoplay--hidden vjs-cta-overlay"></div>'
  ).html(getMarkup(player));

  // Append to DOM
  $(player.el()).append($wrapper);
}

function getMarkup(player) {
  const buyPath = player.el().dataset.buyPath;
  const buyText = player.el().dataset.buyText;
  const giftPath = player.el().dataset.giftPath;

  // Markup has been separated as preorder videos shouldn't
  // have a gift cta
  const markupStart = `
    <button class="vjs-fullscreen-control vjs-control vjs-cta-overlay__close ${CLASS_CLOSE_OVERLAY}" title="Exit Fullscreen" type="button">
      <span aria-hidden="true" class="vjs-icon-placeholder"></span>
    </button>
    <div class="vjs-autoplay__content vjs-autoplay--hidden">
      <div class="h3 text-center text-tertiary mb-3 mb-lg-5 d-none d-sm-block">Ready to get started?</div>
      <div class="vjs-autoplay__btn-box vjs-cta-overlay__btns">`;

  const buyCta = `
    <a class="btn btn-cta btn-primary has-underline--tab-slide js-buy-course vjs-cta-overlay__btns--btn" data-remote="true" href="${buyPath}">
      <span>${buyText}</span>
    </a>`;

  const giftCtaMarkup = `
    <a class="btn btn-cta btn-ghost btn-ghost-dark has-underline--tab-slide bg-transparent js-gift-course vjs-cta-overlay__btns--btn" data-remote="true" href="${giftPath}">
      <span>Gift course</span>
    </a>
  `;

  const giftCta = giftPath ? giftCtaMarkup : '';

  const markupEnd = `
      </div>
      <div class="d-none d-xs-block mt-sm-4 mt-md-5">
        <button class="vjs-autoplay__btn js-autoplay-restart mx-auto">
          <span class="vjs-autoplay__btn--restart"></span>
          Restart
        </button>
      </div>
    </div>
  `;

  if (player.el().dataset.coursePreorder) {
    return markupStart + buyCta + markupEnd;
  } else {
    return markupStart + buyCta + giftCta + markupEnd;
  }
}
