import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    id: String,
  };

  EVENT_NAME = "Platform Link Click";

  connect() {
    if (!this.checkValidityOfValues()) return;

    switch (this.trackingMethod()) {
      case "track":
        this.onRemoteLinkClick = this.onRemoteLinkClick.bind(this);
        this.element.addEventListener("click", this.onRemoteLinkClick);
        break;
      case "trackLink":
        window.analytics.trackLink(
          this.element,
          this.EVENT_NAME,
          this.eventProperties()
        );
        break;
    }
  }

  trackingMethod() {
    if (this.isLinkRemote() || this.isHrefBlank()) {
      return "track";
    }

    return "trackLink";
  }

  isLinkRemote() {
    return !!this.element.dataset.remote;
  }

  isHrefBlank() {
    return [null, undefined, "", "#"].includes(
      this.element.getAttribute("href")
    );
  }

  disconnect() {
    if (this.element.dataset.remote) {
      this.element.removeEventListener("click", this.onRemoteLinkClick);
    }
  }

  onRemoteLinkClick() {
    window.analytics.track(this.EVENT_NAME, this.eventProperties());
  }

  checkValidityOfValues() {
    if (this.idValue) return true;

    // eslint-disable-next-line no-console
    console.error(
      "Link tracking ID is required when using the analytics-link-tracking controller"
    );
    return false;
  }

  eventProperties() {
    return {
      element_id: this.idValue,
      via: document.location.href,
      params: Object.fromEntries(new URLSearchParams(document.location.search)),
    };
  }
}
