const SELECTOR_CONTAINER = '.js-lazy-container';
const SELECTOR_LAZY_ITEMS = "*[class*='bm-lazy-']";
const CLASS_LAZY_LOADED = 'bm-lazy--loaded';
const CLASS_LAZY_IMAGES = 'js-lazy-img';

export default function setupLazyLoad() {
  if ('IntersectionObserver' in window) {
    // Lazy load images
    const lazyObserver = new IntersectionObserver(entries => {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          const lazyImage = entry.target;

          if ($(lazyImage).hasClass('js-bg-lazy-img')) {
            $(lazyImage).attr(
              'style',
              `background-image: url(${lazyImage.dataset.src})`
            );
          } else {
            lazyImage.src = lazyImage.dataset.src;
          }

          lazyImage.classList.remove(CLASS_LAZY_IMAGES);
          lazyObserver.unobserve(lazyImage);
        }
      });
    });
    $(`.${CLASS_LAZY_IMAGES}`).each((ind, lazyImage) => {
      lazyObserver.observe(lazyImage);
    });

    // Animate when scrolled into view
    const lazyImageObserver = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const els = $(entry.target).find(SELECTOR_LAZY_ITEMS);

            $(els).each((ind, el) => {
              $(el).addClass(CLASS_LAZY_LOADED);

              // Run object-fit polyfill as real dimensions are used for calculations
              // Sometimes containers are observed, so find the img tag first
              if (el.tagName !== 'IMG') {
                window.objectFitPolyfill($(el).find('img'));
              } else {
                window.objectFitPolyfill($(el));
              }
            });
          }
        });
      },
      { threshold: [0.3] }
    );
    $(SELECTOR_CONTAINER).each((ind, lazyEl) => {
      lazyImageObserver.observe(lazyEl);
    });
  } else {
    // If polyfill fails, load all images...
    $(`.${CLASS_LAZY_IMAGES}`).each((ind, img) => {
      img.src = img.dataset.src;
    });

    // ...and fade in all elements
    $(SELECTOR_LAZY_ITEMS).each((ind, el) => {
      $(el).removeClass(function(index, className) {
        return (className.match(/(^|\s)bm-lazy-\S+/g) || []).join(' ');
      });
    });
  }
}
