export async function transitionElement(el, type, transitionClass) {
  await runTransition(el, type, transitionClass);
}

function runTransition(el, type, transitionClass) {
  return new Promise(resolve => {
    const removeAndResolve = () => {
      el.removeEventListener('transitionend', removeAndResolve);
      resolve();
    };

    el.addEventListener('transitionend', removeAndResolve);

    if (type === 'remove') {
      el.classList.remove(transitionClass);
    } else if (type === 'add') {
      el.classList.add(transitionClass);
    }

    // A fallback if the transition doesn't end
    setTimeout(() => {
      removeAndResolve();
    }, 500);
  });
}
