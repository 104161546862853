import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    cookieValue: {
      type: String,
      default: "true",
    },
    name: String,
    userId: String,
    endTime: String,
  };

  set() {
    document.cookie = `${this.cookieName()}=${
      this.cookieValueValue
    }; expires=${this.expiryDate()}; path=/`;
  }

  expiryDate() {
    return new Date(this.endTimeValue);
  }

  cookieName() {
    return `${this.nameValue}-${this.userIdValue}`;
  }
}
