import { canAccessLocalStorage } from "../../javascripts/utils";

export default function setupVolumeSaving(
  player,
  { localStorageKey = 'bbcm-volume' } = {}
) {
  if (!canAccessLocalStorage()) return;

  const savedVolume = localStorage.getItem(localStorageKey);
  if (savedVolume) {
    player.volume(parseFloat(savedVolume));
  }
  player.on('volumechange', function() {
    localStorage.setItem(localStorageKey, player.volume());
  });
}
