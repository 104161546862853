import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "trigger"];

  declare checkboxTargets: Array<HTMLInputElement>;
  declare triggerTarget: HTMLInputElement;

  connect() {
    if (this.triggerTarget.checked) {
      this.checkAll();
    }

    if (this.areAllChecked()) {
      this.triggerTarget.checked = true;
    }
  }

  toggle() {
    this.triggerTarget.checked = this.areAllChecked();
  }

  toggleAll() {
    if (this.areAllChecked()) {
      this.unCheckAll();
    } else {
      this.checkAll();
    }
  }

  areAllChecked() {
    return this.checkboxTargets.every((checkbox) => checkbox.checked);
  }

  checkAll() {
    this.checkboxTargets.forEach((checkbox) => (checkbox.checked = true));
  }

  unCheckAll() {
    this.checkboxTargets.forEach((checkbox) => (checkbox.checked = false));
  }
}
