import { canAccessLocalStorage } from "../../javascripts/utils";

export default function setupPlaybackSpeedSetting(player) {
  if (!canAccessLocalStorage()) return;

  const PLAYBACK_RATE_KEY = "bbcm-playback-rate";
  const DEFAULT_PLAYBACK_RATE = 1;
  const PLAYBACK_RATE =
    localStorage.getItem(PLAYBACK_RATE_KEY) !== null
      ? getPlaybackRate()
      : DEFAULT_PLAYBACK_RATE;

  player.on("ready", function () {
    player.defaultPlaybackRate(PLAYBACK_RATE);
    player.playbackRate(PLAYBACK_RATE);
  });

  player.on("ratechange", function () {
    // We need to set the default play back rate here otherwise the native browser behaviour
    // will default this back to 1 and not keep the preference specified
    player.defaultPlaybackRate(player.playbackRate());
    setPlaybackRate();
  });

  function getPlaybackRate() {
    return JSON.parse(localStorage.getItem(PLAYBACK_RATE_KEY));
  }

  function setPlaybackRate() {
    localStorage.setItem(
      PLAYBACK_RATE_KEY,
      JSON.stringify(player.playbackRate())
    );
  }
}
