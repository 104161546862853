import { postDataToEndpoint } from "../utils/postDataToEndpoint";

const URL_ENDPOINT = "/practice_progresses";
const RUN_INTERVAL = 10;

export default function setupPracticeProgressEvents(player) {
  function createEventHandler(player) {
    return function (e: Event) {
      const practiceId = player.el().dataset.practiceId;
      const currentTime = player.currentTime();
      const trigger_type = e ? e.type : "interval";

      const data = {
        practice_id: practiceId,
        current_time: currentTime,
        trigger_type: trigger_type,
      };

      postDataToEndpoint(URL_ENDPOINT, data);
    };
  }

  const eventHandler = createEventHandler(player);

  player.setInterval(function (e: Event) {
    if (!player.paused) {
      eventHandler(e);
    }
  }, RUN_INTERVAL);

  return eventHandler;
}
