import { Controller } from "@hotwired/stimulus";

const template = `
<div class="bm-container-devise">
  <div class="bm-container-devise__content-main">
    <div class="bm-devise-layout__content text-center">
      <div class="bm-loader bm-loader--dark"></div>
      <span class="bm-sub-heading">Loading...</span>
    </div>
  </div>
</div>
`;

export default class extends Controller {
  open() {
    document.querySelector(".js-modals").innerHTML = template;
    window.showModal();
  }
}
