// Small pieces of behaviour that don't warrant their own file
export default function setupUtilityJS() {
  // Scroll to top of the page on click
  $(document).on('click', '.js-back-to-top', e => {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, 600, function() {
      $('.bm-header__logo').focus();
    });
  });
}
