const SELECTOR_BTN = '.js-scroll-to-next';

export default function scrollToNextSection() {
  $(document).on('click', SELECTOR_BTN, e => {
    let $nextSection = $(e.target)
      .closest('section')
      .next('section, article')
      .eq(0);

    if ($nextSection.hasClass('js-skip-section-on-scroll')) {
      $nextSection = $nextSection.next('section, article');
    }
    const offset = $nextSection.offset().top;
    const headerHeight = $('header').height();

    window.scroll({ top: offset - headerHeight, left: 0, behavior: 'smooth' });
  });
}
