import debounce from 'lodash/debounce';

const SELECTOR_SLIDER = '.js-lesson-slider';
const SELECTOR_SLIDER_BTN = '.js-lesson-slider-btn';
const SLIDER_CONTAINER = '.js-lessons-slider-container';
const SLIDER_BTN_CONTAINER = '.js-lessons-slider-btn-container';

export default function setupLessonSlider() {
  if ($(SELECTOR_SLIDER).length) {
    sliderInit();
  }

  function sliderInit() {
    let slider = $(SELECTOR_SLIDER)[0];
    let sliderWidth = $(slider).width();
    let scrollableWidth = $(slider)[0].scrollWidth;

    // Check whether buttons should disable
    btnDisableCheck();
    $(slider).on('scroll', debounce(btnDisableCheck, 100));

    // Scroll on click
    $(SELECTOR_SLIDER_BTN).on('click', e => {
      scrollSlider(e.target);
    });

    // Prevent initialise check for every pixel
    $(window).resize(() => {
      if (timer) {
        clearTimeout(timer);
      }
      const timer = setTimeout(() => {
        $(window).trigger('onSliderResizeEnd');
      }, 500);
    });
    // Then recaculate dimensions
    $(window).bind('onSliderResizeEnd', () => {
      calculateValues();
    });

    function calculateValues() {
      sliderWidth = $(slider).width();
      scrollableWidth = $(slider)[0].scrollWidth;
      btnDisableCheck();
    }

    function scrollSlider(btn) {
      let operator = '';

      if ($(btn).data('direction') == 'next') {
        operator = '+';
      } else {
        operator = '-';
      }

      slider.scrollBy({
        top: 0,
        left: operator + sliderWidth,
        behavior: 'smooth'
      });
    }

    function btnDisableCheck() {
      const fullSliderWidth =
        $(SLIDER_CONTAINER).width() - $(SLIDER_BTN_CONTAINER).outerWidth(true);

      if (scrollableWidth > fullSliderWidth) {
        if ($(slider).scrollLeft() <= 0) {
          toggleBtnState($(SELECTOR_SLIDER_BTN)[0]);
          $(SELECTOR_SLIDER_BTN)
            .eq(1)
            .removeAttr('disabled');
        } else if (
          $(slider).scrollLeft() >=
          scrollableWidth - 10 - Math.round(sliderWidth)
        ) {
          toggleBtnState($(SELECTOR_SLIDER_BTN)[1]);
          $(SELECTOR_SLIDER_BTN)
            .eq(0)
            .removeAttr('disabled');
        } else {
          $(SELECTOR_SLIDER_BTN).removeAttr('disabled');
        }
      } else {
        $(SELECTOR_SLIDER_BTN).attr('disabled', 'true');
      }
    }

    function toggleBtnState(btns) {
      $(btns).each((ind, btn) => {
        if (btn.classList.contains('disabled')) {
          btn.classList.add('disabled');
          btn.removeAttribute('disabled');
        } else {
          btn.classList.remove('disabled');
          btn.setAttribute('disabled', 'true');
        }
      });
    }
  }
}
