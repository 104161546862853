import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    threshold: {
      type: Number,
      default: 1.0,
    },
    rootMargin: {
      type: String,
      default: "10px",
    },
    position: {
      type: Array,
      default: ["left", "right"],
    },
  };

  static classes = ["tag", "wrapper"];

  connect() {
    this.initializeElems();
  }

  getEdgeName = (edge: string) => `${this.tagClass}-${edge}`;

  wrapElems(wrapper: HTMLElement, elem: HTMLElement) {
    const elems = elem.childNodes;
    while (elems.length) {
      wrapper.appendChild(elems[0]);
    }
    elem.appendChild(wrapper);
  }

  initializeElems() {
    const wrapper = document.createElement("div");

    // If we are using "left" in our position values then we need to
    // make the wrapper a flex container so that the intersecting divs sit
    // at the extreme left and right hand sides instead of top and bottom
    if (this.positionValue.includes("left")) {
      wrapper.classList.add("d-flex");
    }

    wrapper.classList.add(...this.wrapperClasses);
    this.wrapElems(wrapper, this.element);
    this.setupIntersectObserver(wrapper);
  }

  setupIntersectObserver(root: HTMLElement) {
    this.positionValue.forEach((edge) => {
      const edgeElem = document.createElement("div");

      // An edge can be 1 of 4 places, left, right, top and bottom.
      // In the cases of "left" and "top" we want to prepend a div
      // and for the opposite cases "right" and "bottom" we want to
      // append a div to our container element
      root[edge === "left" || edge === "top" ? "prepend" : "append"](edgeElem);

      const options = {
        root,
        rootMargin: this.rootMarginValue,
        threshold: this.thresholdValue,
      };

      const callback = (entries) => {
        entries.forEach((entry) => {
          const parentElement = root.parentNode as HTMLElement;
          parentElement.classList[entry.isIntersecting ? "remove" : "add"](
            this.getEdgeName(edge)
          );
        });
      };
      const observer = new IntersectionObserver(callback, options);
      observer.observe(edgeElem);
    });
  }
}
