const REWATCH_BUTTON = ".js-autoplay-restart";
const END_CARD_ELEMENT = ".js-end-card";

export default function setupVideoEndCard(player) {
  const endCard = document.querySelector(END_CARD_ELEMENT) as HTMLElement;
  const rewatchBtn = document.querySelector(
    REWATCH_BUTTON
  ) as HTMLButtonElement;

  player.on("ended", () => {
    endCard.dataset.visible = "true";
    rewatchBtn.addEventListener("click", () => {
      endCard.dataset.visible = "false";
      player.play();
      setTimeout(() => {
        player.currentTime(0);
      }, 10);
    });
  });
}
