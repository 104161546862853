import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.originalContent = this.element.innerHTML;
    this.handleClick = this.handleClick.bind(this);
    this.handleConfirmCancelation = this.handleConfirmCancelation.bind(this);
    this.element.addEventListener("click", this.handleClick);
    this.element.addEventListener(
      "confirm:complete",
      this.handleConfirmCancelation
    );
  }

  disconnect() {
    this.element.removeEventListener("click", this.handleClick);
  }

  handleConfirmCancelation(event) {
    if (!event.detail[0]) {
      this.element.setAttribute("disabled", false);
      this.element.classList.remove("disabled");
      this.element.innerHTML = this.originalContent;
    }
  }

  handleClick() {
    this.element.setAttribute("disabled", true);
    this.element.classList.add("disabled");
    this.element.innerHTML = "Loading...";
  }
}
