const SELECTOR_FORM = '.js-mailing-list';

export default function setupMailingList() {
  mailingListInit();
  $(document).on('turbolinks:load', mailingListInit);

  function mailingListInit() {
    $(SELECTOR_FORM)
      .attr('novalidate', '')
      .on('submit', e => {
        e.preventDefault();

        const $form = $(e.target);
        const $inputField = $form.find('input[type=email]');

        if (emailIsValid($form, $inputField)) {
          $form.find('button').attr('disabled', true);
          $.ajax({
            url: '/mailing_list',
            method: 'POST',
            dataType: 'json',
            data: {
              email: $inputField.val(),
              via: $form.attr('data-via'),
              label: $form.attr("data-label"),
              course_id: $form.attr('data-course-id'),
              segment_event: $form.attr('data-segment-event')
            },
            success: function() {
              window.analytics.track('CourseTrial');

              let successMessage = `<div data-controller="clipboard">
                    <div>
                      <p class="d-inline-block mb-0 text-neutral-50">
                        Thanks for signing up <br> Your unique 10% discount code is
                        <span class="d-inline-block position-relative">
                          <span class="position-relative text-neutral-50" data-clipboard-target="source">
                            NEW768933
                          </span>
                          <span class="position-relative">
                            <button class="btn-reset | d-inline-block text-neutral-50" data-action="clipboard#copy" type="button">
                              <span class="sr-only">Copy to clipboard</span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 9h-9a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-9a2 2 0 0 0-2-2Z"/><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"/></svg>
                            </button>
                            <span role="alert" aria-atomic="true" data-clipboard-target="success" class="bm-tooltip | bm-font-weight-medium bg-primary-500 font-sm position-absolute p-1 rounded text-white">
                              <span class="text-neutral-50">Copied</span>
                            </span>
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                `;
              if ($form.attr("data-via") === "course-prelaunch-page") {
                successMessage =
                  "Thanks for signing up. Keep an eye on your inbox for the latest updates.";
              } else if ($form.attr("data-course-id")) {
                successMessage =
                  "Thanks for signing up to receive your free lesson. Check your inbox - it's on the way!";
              }

              $form
                .addClass("text-center text-md-left")
                .html(
                  `<div class="bm-form-success-message">${successMessage}</div>`
                );
            },
            error: function() {
              $form
                .css('color', '#b00020')
                .addClass('text-center text-md-left')
                .append(
                  'There was an error, please refresh the page and try again'
                );
            }
          });
        }
      });
  }
}

function emailIsValid($form, $inputField) {
  const email = $inputField.val();
  if (email.length === 0) {
    renderError($form, $inputField, "Email address can't be blank");
  } else if (!email.includes('@')) {
    renderError($form, $inputField, 'Email address must include an "@"');
  } else if (email.substring(email.length - 1) == '@') {
    renderError($form, $inputField, 'Please enter a part following "@"');
  } else {
    return true;
  }
}

function renderError($form, $inputField, msg) {
  const $errorEl = $form.find('.invalid-feedback');
  $errorEl.text(msg).show();

  $inputField.addClass('is-invalid');
}
