import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["video"];

  connect() {
    this.videoId = crypto.randomUUID();

    this.resetVideo.bind(this);
    this.pauseOutOfFocus.bind(this);

    this.videoTarget.addEventListener("ended", () => this.resetVideo());
    document.addEventListener("video-poster-play", (e) =>
      this.pauseOutOfFocus(e.detail.videoId)
    );
  }

  disconnect() {
    this.videoTarget.removeEventListener("ended", () => this.resetVideo());
    document.removeEventListener("video-poster-play", (e) =>
      this.pauseOutOfFocus(e.detail.videoId)
    );
  }

  resetVideo() {
    this.showPlayButton();
    this.videoTarget.currentTime = 0;
    this.videoTarget.pause();
  }

  togglePlay() {
    if (this.videoTarget.paused) {
      this.hidePlayButton();
      this.stopOtherVideos();
      this.videoTarget.play();
    } else {
      this.videoTarget.pause();
      this.showPlayButton();
    }
  }

  pauseOutOfFocus(eventVideoId) {
    if (!(eventVideoId == this.videoId)) {
      this.videoTarget.pause();
      this.showPlayButton();
    }
  }

  stopOtherVideos() {
    document.dispatchEvent(
      new CustomEvent("video-poster-play", {
        detail: { videoId: this.videoId },
      })
    );
  }

  hidePlayButton() {
    this.element
      .querySelector(".vc-video-testimonial__overlay__icon")
      .classList.add("d-none");
  }

  showPlayButton() {
    this.element
      .querySelector(".vc-video-testimonial__overlay__icon")
      .classList.remove("d-none");
  }
}
