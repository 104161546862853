import videojs from 'video.js';
import { isMobileDevice } from './helpers';
//import './videojs.thumbnails';

export default function setupVideoChapters(thumbnailsJson) {
  // Format json to object
  if (thumbnailsJson.length > 2) {
    const thumbnailsArr = JSON.parse(thumbnailsJson).map(x => {
      return Object.values(x);
    });

    const thumbnailsObj = {};
    thumbnailsArr.forEach(z => {
      thumbnailsObj[z[0]] = { src: z[1] };
    });

    const player = videojs.getAllPlayers()[0];
    if (player) {
      chaptersInit(player, thumbnailsObj);
    }
  }

  function chaptersInit(player, thumbnails) {
    player.on('loadedmetadata', function() {
      const duration = player.duration();
      const progressControl = player.controlBar.progressControl;
      const seekBar = progressControl.seekBar;

      Object.entries(thumbnails).forEach(el => {
        // Add all markers
        addMarker(el, seekBar, duration, player);
      });

      // Add thumbnail element, only one per video
      addThumbnailEl(player);

      const markers = $('.vjs-chapter-marker');
      const thumbnail = $('.vjs-thumbnail');
      // Keep state
      let hasThumbnail = false;
      // Sensitivity for mobile trigger
      const thresholdPercentage = 5;

      if (isMobileDevice()) {
        progressControl.on('touchmove', event => {
          moveListenerMobile(event);
        });
      } else {
        progressControl.on('mousemove', moveListener);
      }

      // For mobile, calculate the percentage from the
      // left, taking into account progress bar width & padding
      function moveListenerMobile(event) {
        const pageXOffset = getScrollOffset().x;

        let pageX = event.pageX;
        if (event.changedTouches) {
          pageX = event.changedTouches[0].pageX;
        }

        let left =
          pageX ||
          event.clientX +
            document.body.scrollLeft +
            document.documentElement.scrollLeft;

        left -=
          offsetParent(progressControl.el()).getBoundingClientRect().left +
          pageXOffset;

        const width = $(player.controlBar.el()).innerWidth();
        const touchScrollPos = ((left - 16) / width) * 100;

        markers.each((ind, el) => {
          const src = $(el).attr('data-thumbnail-src');
          const posLeft = el.style.left.replace(/%/g, '');

          // If scroll position is within
          if (
            posLeft > touchScrollPos - thresholdPercentage &&
            posLeft < touchScrollPos + thresholdPercentage
          ) {
            showThumbnail(el, src);
          } else if (hasThumbnail && thumbnail.attr('src') === src) {
            hideThumbnail();
          }
        });
      }

      // For desktop calculate whether mouse playhead
      // overlaps a marker
      function moveListener() {
        const rect1 = seekBar.mouseTimeDisplay.el().getBoundingClientRect();

        $(markers).each((ind, el) => {
          const rect2 = el.getBoundingClientRect();
          const overlap = !(
            rect1.right < rect2.left || rect1.left > rect2.right
          );
          const src = $(el).attr('data-thumbnail-src');

          if (overlap) {
            showThumbnail(el, src);
          } else if (hasThumbnail && thumbnail.attr('src') === src) {
            hideThumbnail();
          }
        });
      }

      function showThumbnail(el, src) {
        const markerPos = parseInt($(el).css('left'), 10);
        const leftPos = markerPos - 70;
        thumbnail.css('left', `${leftPos}px`).attr('src', src);
        thumbnail.show();
        hasThumbnail = true;
      }

      function hideThumbnail() {
        thumbnail.hide();
        thumbnail.attr('src', '');
        hasThumbnail = false;
      }
    });
  }
}

function addMarker(el, seekBar, duration) {
  const marker = document.createElement('div');
  marker.className = 'vjs-chapter-marker';
  const markerPosition = ((el[0] / duration) * 100).toFixed(2);
  $(marker).css('left', `${markerPosition}%`);
  $(marker).attr('data-thumbnail-src', el[1].src);

  seekBar.el().appendChild(marker);
}

function addThumbnailEl(player) {
  const div = document.createElement('div');
  div.className = 'vjs-thumbnail-holder';
  const img = document.createElement('img');
  div.appendChild(img);
  img.src = '';
  img.className = 'vjs-thumbnail';
  player.controlBar.progressControl.el().appendChild(div);
}

// Functions to calculate mobile scroll position
function getScrollOffset() {
  if (window.pageXOffset) {
    return {
      x: window.pageXOffset,
      y: window.pageYOffset
    };
  }
  return {
    x: document.documentElement.scrollLeft,
    y: document.documentElement.scrollTop
  };
}

function getComputedStyle(el, pseudo) {
  return function(prop) {
    if (window.getComputedStyle) {
      return window.getComputedStyle(el, pseudo)[prop];
    } else {
      return el.currentStyle[prop];
    }
  };
}

function offsetParent(el) {
  if (el.nodeName !== 'HTML' && getComputedStyle(el)('position') === 'static') {
    return offsetParent(el.offsetParent);
  }
  return el;
}
