import { isMobileDevice } from './helpers';

export default function setupMobileTextTracks(player, videoEl) {
  // Handle native text track positioning
  player.on('useractive', () => {
    moveCueDecide();
  });

  player.on('userinactive', () => {
    moveCueDecide();
  });

  player.on('play', () => {
    if (isMobileDevice()) {
      moveCueUp();

      // Show control bar for non-turbolinks visits
      $(player.el())
        .find('.vjs-control-bar')
        .removeAttr('style');
    }
  });

  function moveCueDecide() {
    if (isMobileDevice()) {
      if (
        $(player.el()).hasClass('vjs-user-active') ||
        $(player.el()).hasClass('vjs-paused')
      ) {
        moveCueUp();
      } else if ($(player.el()).hasClass('vjs-user-inactive')) {
        if ($(player.el()).hasClass('vjs-paused')) {
          moveCueUp();
        } else {
          moveCueDown();
        }
      }
    }
  }
  function moveCueUp() {
    const track = videoEl.textTracks[0];
    if (track && track.activeCues.length) {
      // Position the cue at the top
      track.activeCues[0].line = 3;
    }
  }
  function moveCueDown() {
    const track = videoEl.textTracks[0];
    if (track && track.activeCues.length) {
      // Position the cue at the bottom
      track.activeCues[0].line = -1;
    }
  }

  player.on('play', () => {
    moveCueUp();
    const track = videoEl.textTracks[0];

    $(track).on('cuechange', () => {
      moveCueDecide();
    });
  });
}
