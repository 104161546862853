/*
 * Runs a function at a specific interval.
 * interval [integer]: Amount in seconds where function should run.
 * callback [function]: To be ran at a desired point in the video.
 * elseCallback [function]: Will run every second before time specified.
 */
export const runAtInterval = (player, interval, callback) => {
  if (
    player.el().dataset.noCourseAccessLesson != undefined ||
    player.el().dataset.trackDuration != undefined ||
    player.el().dataset.trackPreSales != undefined
  ) {
    player.one('loadedmetadata', function() {
      //const marker = duration - displayAt;

      const intervalInMilliseconds = interval * 1000;
      player.setInterval(function() {
        if (!player.paused()) {
          callback();
        }
      }, intervalInMilliseconds);
    });
  }
};

/*
 * Runs a function at a specific point in the video.
 * displayAt [integer]: Amount in seconds where function should run. It will be used to calculate time before the end of the video where the function should occur.
 * callback [function]: To be ran at a desired point in the video.
 * elseCallback [function]: Will run every second before time specified.
 */
export const runAtTime = (player, displayAt, callback, elseCallback = null) => {
  player.one('loadedmetadata', function() {
    const duration = player.duration();
    const marker = duration - displayAt;

    player.on('timeupdate', () => {
      const avgCurrentTime = Math.floor(player.currentTime());
      if (avgCurrentTime >= marker) {
        callback();
      } else {
        elseCallback ? elseCallback() : null;
      }
    });
  });
};

/*
 * Skips time with a custom amount of seconds and option of
 * moving back.
 * amount [integer]: Amount in seconds time should be skipped.
 * back [boolean]: Will skip time backwards when set to true.
 */
export const skipTime = (player, amount = 30, back = false) => {
  const direction = back
    ? player.currentTime() - amount
    : player.currentTime() + amount;

  player.currentTime(direction);
};

/*
 * Sets the scene to any source provided, plays it immediately.
 * player [object]: A videoJS player instance.
 * clipSource [boolean]: A path or url to a video file with supported format.
 */
export const setScene = (player, clipSource, play = false) => {
  player.src({ src: clipSource, type: 'application/x-mpegURL' });
  if (play) {
    player.play();
  }
};

/*
 * Returns the first number from iOS version.
 * e.g iOS 13.4.1 will return 13
 * This is unreliable for iOS versions under 14
 */
export const getIOSVersionNumber = () => {
  if (getMobileOperatingSystem() === 'iOS') {
    var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (v != undefined) {
      var version = [
        parseInt(v[1], 10),
        parseInt(v[2], 10),
        parseInt(v[3] || 0, 10)
      ];
      return version[0];
    }
  }
};

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/iPhone/.test(userAgent)) {
    return "iPhone";
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  let isIOS =
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  if (isIOS && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
};

/**
 * Returns whether the page is being viewed on a mobile device running iOS or Android. Used to determine which fullscreen type to use.
 * @returns {Boolean}
 */
export const isMobileDevice = () => {
  let condition =
    getMobileOperatingSystem() === 'iOS' ||
    getMobileOperatingSystem() === 'Android';
  return condition;
};

/*
 * Toggles a class that expands the player to take fullscreen. A solid black underlay is added to prevent flashes when changing device orientation.
 * player [object]: A videoJS player instance.
 * className [string]: The name of the class which will apply the styles to the player.
 */
export const toggleFullscreen = (player, className) => {
  if (player.hasClass(className)) {
    player.removeClass(className);
    $('.bm-mobile-video-underlay').removeClass(
      'bm-mobile-video-underlay--show'
    );
    $('.bm-header').css('z-index', '100');
  } else {
    player.addClass(className);
    $('.bm-mobile-video-underlay').addClass('bm-mobile-video-underlay--show');
    // iOS Safari will show the navbar over video
    // unless z-index is removed
    $('.bm-header').css('z-index', 'auto');
  }
};

/*
 * Returns the height of an element, including borders and padding. Used to set to parent height.
 * element [DOM element]: A DOM element to be inspected for offset height.
 */
const getElementHeight = element => {
  return element.offsetHeight;
};

/*
 * Attempts to retrieve control bar position. Used to prevent choices bar from overlapping the control bar.
 * controlBar [object]: A videoJS controlBar instance. e.g player.controlBar
 */
export const getControlBarHeight = controlBar => {
  const elementHeight = parseInt(
    window
      .getComputedStyle(controlBar.el(), null)
      .getPropertyValue('height')
      .slice(0, -2)
  );

  const bottomProp = parseInt(
    window
      .getComputedStyle(controlBar.el(), null)
      .getPropertyValue('bottom')
      .slice(0, -2)
  );

  return elementHeight + bottomProp;
};

/*
 * Sets an element height to the same of its parent. Will update as window changes.
 * element [DOM element]: A DOM element for the height to be applied to.
 * reduce [integer/float]: Useful when accounting for other elements, this value will be removed from the final height value.
 */
export const setToParentHeight = (element, reduce = 0) => {
  const parentHeight = getElementHeight(element.parentElement);
  const elementHeight = parentHeight - reduce + 'px';
  element.style.height = elementHeight;

  window.onresize = function() {
    element.style.height = elementHeight;
  };
};

/*
 * Returns text tracks length
 */
export const videoHasCaptions = video => {
  return video.textTracks.length;
};
