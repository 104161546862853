import { Controller } from "@hotwired/stimulus";
import clamp from "lodash/clamp";
import inRange from "lodash/inRange";

export default class extends Controller {
  static targets = [
    "list",
    "nextButton",
    "item",
    "link",
    "previousButton",
    "nextButton",
  ];
  static draggingStartThreshold = 10;
  private draggingWidth = 768; // This eqautes to the `md` breakpoint as part of Bootstrap

  initialize() {
    this.currentIndex = 0;
    this.translateX = 0;
    this.dragging = false;
    this.disabled = false;
  }

  connect() {
    this.itemTotal = this.itemTargets.length;
    this.setCurrentPerPage();
    this.updateNavigation();
    this.updateActiveItems();
    this.bindEvents();
    if (window.innerWidth > this.draggingWidth) {
      this.bindDragEvents();
    }
  }

  bindEvents() {
    this.onWindowResize = this.onWindowResize.bind(this);
    window.addEventListener("resize", this.onWindowResize);
    window.addEventListener("turbolinks:render", this.onWindowResize);
  }

  bindDragEvents() {
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragMove = this.onDragMove.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.listTarget.addEventListener("mousedown", this.onDragStart);
    this.listTarget.addEventListener("touchstart", this.onDragStart);
  }

  unBindDragEvents() {
    this.listTarget.removeEventListener("mousedown", this.onDragStart);
    this.listTarget.removeEventListener("touchstart", this.onDragStart);
  }

  setCurrentPerPage() {
    this.currentPerPage = parseInt(
      getComputedStyle(this.element).getPropertyValue("--vc-carousel-items")
    );
    this.maxClamp = this.itemTotal - this.currentPerPage;
    this.disableCarouselIfItemsDoNotExceedPerPage();
  }

  disableCarouselIfItemsDoNotExceedPerPage() {
    this.disabled = this.itemTotal <= this.currentPerPage;
    this.element.classList.toggle("vc-carousel--disabled", this.disabled);
  }

  onWindowResize() {
    if (this.itemTotal <= this.currentPerPage) return;

    this.unBindDragEvents();
    this.disbaled = false;

    if (window.innerWidth > this.draggingWidth) {
      this.bindDragEvents();
    } else {
      this.disabled = true;

      // There is a scenario where a user can get "stuck" without being able to scroll to the
      // extreme left hand side of the carousel during resizing. By setting the transform value
      // to translateX(0), we force the carousel to go back to the beginning so that all items within
      // it are all still accessible.
      this.listTarget.style.transform = "translateX(0)";

      // If this condition is met then we don't want to run the other methods in this function such
      // as this.setCurrentPage(), this.updateCurrentIndexWithClamp() and this.animateToCurrentIndex()
      // due to these handling the blur/no blur effect of carousel items which is only present at widths
      // greater than out draggingWidth constant e.g. 768px
      return;
    }

    this.setCurrentPerPage();
    // Changing the currentPerPage may impact the maximum currentIndex so we recompute it
    this.updateCurrentIndexWithClamp(0);
    this.animateToCurrentIndex();
  }

  preventDragOnLinks(event) {
    event.preventDefault();
  }

  onDragStart(event) {
    if (this.disabled) return;

    switch (event.type) {
      case "mousedown":
        window.addEventListener("mousemove", this.onDragMove);
        window.addEventListener("mouseup", this.onDragEnd);
        this.dragStartX = event.clientX;
        break;
      case "touchstart":
        window.addEventListener("touchmove", this.onDragMove);
        window.addEventListener("touchend", this.onDragEnd);
        this.dragStartX = event.touches[0].clientX;
        break;
    }

    this.listTarget.classList.add("vc-carousel__list--dragging");
    this.dragStartTranslateX = this.translateX;
  }

  preventNavigateOnDrag(event) {
    if (this.dragging) event.preventDefault();
  }

  onDragMove(event) {
    switch (event.type) {
      case "mousemove":
        this.dragCurrentX = event.clientX;
        break;
      case "touchmove":
        if (this.dragging) event.preventDefault(); // Disables scrolling on mobile
        this.previousDragCurrentX = this.dragCurrentX;
        this.dragCurrentX = event.touches[0].clientX;
        break;
    }

    if (!this.dragging && this.draggingThresholdReached()) this.dragging = true;

    const dragDelta = this.dragCurrentX - this.dragStartX;
    this.translateX = this.dragStartTranslateX + dragDelta;

    this.listTarget.style.transform = `translateX(${this.translateX}px)`;
  }

  draggingThresholdReached() {
    return (
      Math.abs(this.dragCurrentX - this.dragStartX) >
      this.constructor.draggingStartThreshold
    );
  }

  onDragEnd(event) {
    setTimeout(() => (this.dragging = false), 50);
    this.listTarget.classList.remove("vc-carousel__list--dragging");

    switch (event.type) {
      case "mouseup":
        window.removeEventListener("mousemove", this.onDragMove);
        window.removeEventListener("mouseup", this.onDragEnd);
        break;
      case "touchend":
        window.removeEventListener("touchmove", this.onDragMove);
        window.removeEventListener("touchend", this.onDragEnd);
        break;
    }

    const offsets = this.itemTargets.map((item) => item.offsetLeft);
    const closestValue = [...offsets].sort(
      (a, b) => Math.abs(-this.translateX - a) - Math.abs(-this.translateX - b)
    )[0];

    if (event.type === "touchend") {
      const intendedIndex = offsets.indexOf(closestValue);
      const intendedIndexWillChange = intendedIndex !== this.currentIndex;
      const currentPosition = event.changedTouches[0].clientX;
      const lastPosition = this.previousDragCurrentX || currentPosition;
      const momentum = Math.abs(lastPosition - currentPosition);
      const momentumThresholdReached = momentum > 5;

      // Left to right = previous < current = index should lower by one
      const direction =
        this.previousDragCurrentX < event.changedTouches[0].clientX ? -1 : 1;

      this.previousDragCurrentX = null;
      this.dragCurrentX = null;

      if (!intendedIndexWillChange && momentumThresholdReached) {
        this.updateCurrentIndexWithClamp(
          undefined,
          offsets.indexOf(closestValue) + direction
        );
      } else {
        this.updateCurrentIndexWithClamp(
          undefined,
          offsets.indexOf(closestValue)
        );
      }
    } else {
      this.updateCurrentIndexWithClamp(
        undefined,
        offsets.indexOf(closestValue)
      );
    }
  }

  previous() {
    this.updateCurrentIndexWithClamp(-this.currentPerPage);
  }

  next() {
    this.updateCurrentIndexWithClamp(this.currentPerPage);
  }

  updateCurrentIndexWithClamp(changeByValue, explicitValue) {
    this.currentIndex = clamp(
      explicitValue !== undefined
        ? explicitValue
        : this.currentIndex + changeByValue,
      0,
      this.maxClamp
    );
    this.animateToCurrentIndex();
  }

  animateToCurrentIndex() {
    this.translateX = -this.itemTargets[this.currentIndex].offsetLeft;
    this.listTarget.style.transform = `translateX(${this.translateX}px)`;
    this.updateActiveItems();
    this.updateNavigation();
  }

  updateNavigation() {
    this.currentIndex == 0
      ? this.previousButtonTarget.setAttribute("disabled", "disabled")
      : this.previousButtonTarget.removeAttribute("disabled");

    this.currentIndex == this.maxClamp
      ? this.nextButtonTarget.setAttribute("disabled", "disabled")
      : this.nextButtonTarget.removeAttribute("disabled");
  }

  updateActiveItems() {
    this.itemTargets.forEach((item, index) => {
      const active = inRange(
        index,
        this.currentIndex,
        this.currentIndex + this.currentPerPage
      );

      item.classList.toggle("vc-carousel__list__item--active", active);
    });
  }
}
