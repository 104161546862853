async function postDataToEndpoint(url: string, data: Record<string, unknown>) {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error with status: ${response.status}`);
    }

    return true;
  } catch (error) {
    /* eslint-disable no-console */
    console.error(`Error posting to ${url}:`, error);
    /* eslint-enable no-console */
  }
}

export { postDataToEndpoint };
