const SELECTOR_FORM = '.js-recipient-form';

export default function setupECardValidations() {
  $('html').on('submit', SELECTOR_FORM, e => {
    validateName();
    validateEmail();
    validateDate();
    if ($(SELECTOR_FORM).find('.is-invalid').length) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  });

  function validateName() {
    $('.name-error').text('');
    const nameField = $('.js-recipient-name');
    const name = $(nameField).val();

    if (/^[a-zA-Z\s]+$/.test(name)) {
      $(nameField).removeClass('is-invalid');
      $(nameField)
        .next()
        .text('');
      return true;
    } else if (name.length === 0) {
      updateNameError("Name can't be blank");
    } else {
      updateNameError('Name can only contain letters');
    }

    function updateNameError(msg) {
      $(nameField).after(
        `<div class='invalid-feedback name-error'>${msg}</div>`
      );
      $(nameField).addClass('is-invalid');
      $('.name-error').show();
    }
  }

  function validateEmail() {
    $('.email-error').text('');
    const emailField = $('.js-recipient-email');
    const email = $(emailField).val();

    if (/^[^@\s]+@[-a-z0-9.]+\.[a-z.]{2,}$/i.test(email)) {
      $(emailField).removeClass('is-invalid');
      $(emailField)
        .next()
        .text('');
      return true;
    } else if (email.length === 0) {
      updateEmailError("Email can't be blank");
    } else {
      updateEmailError('Email address is invalid');
    }

    function updateEmailError(msg) {
      $(emailField).after(
        `<div class='invalid-feedback email-error'>${msg}</div>`
      );
      $(emailField).addClass('is-invalid');
      $('.email-error').show();
    }
  }

  function validateDate() {
    $('.date-error').text('');
    const dateField = $('.js-delivery-date');
    const date = $(dateField).val();

    if (
      !document
        .querySelector('#js-e-card-calendar')
        .classList.contains('d-none')
    ) {
      if (/^[0-9/]+$/.test(date)) {
        $(dateField).removeClass('is-invalid');
        $(dateField)
          .next()
          .text('');
        return true;
      } else if (date.length === 0) {
        updateDateError("Delivery date can't be blank");
      } else {
        updateDateError('Delivery date is invalid');
      }
    }

    function updateDateError(msg) {
      $(dateField).after(
        `<div class='invalid-feedback date-error'>${msg}</div>`
      );
      $(dateField).addClass('is-invalid');
      $('.date-error').show();
    }
  }
}
