import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source", "success"];

  declare sourceTarget: HTMLElement;
  declare successTarget: HTMLElement;

  copy() {
    const copiedText = this.sourceTarget.textContent.trim();
    navigator.clipboard.writeText(copiedText);
    this.show();
  }

  show() {
    this.successTarget.dataset.success = "true";
    setTimeout(() => {
      this.successTarget.dataset.success = "false";
    }, 3000);
  }
}
