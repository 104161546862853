import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "status", "button", "hideIcon", "showIcon"];

  toggle() {
    if (this.isVisible()) {
      this.hidePassword();
    } else {
      this.showPassword();
    }
  }

  isVisible() {
    return this.inputTarget.type == "text";
  }

  showPassword() {
    this.showIconTarget.classList.toggle("d-none", true);
    this.hideIconTarget.classList.toggle("d-none", false);
    this.buttonTarget.title = "Hide password";
    this.buttonTarget.ariaLabel = "Hide password";

    this.inputTarget.type = "text";
    this.statusTarget.textContent = "Your password is visible";
  }

  hidePassword() {
    this.showIconTarget.classList.toggle("d-none", false);
    this.hideIconTarget.classList.toggle("d-none", true);
    this.buttonTarget.title = "Show password";
    this.buttonTarget.ariaLabel = "Show password";

    this.inputTarget.type = "password";
    this.statusTarget.textContent = "Your password is hidden";
  }
}
