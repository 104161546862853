function dwellTracking(courseId) {
  var idleSeconds = 30;

  const timeout = setTimeout(fireDwellEvent, idleSeconds * 1000);

  $(document).one('turbolinks:before-cache', function() {
    clearTimeout(timeout);
    $('.js-dwell').remove();
  });

  function fireDwellEvent() {
    const url = '/fire_dwell_event';
    const data = {
      course_id: courseId
    };

    $.ajax({
      type: 'POST',
      url: url,
      data: data,
      dataType: 'json'
    });
  }
}

export default function setupDwellTracking(courseId) {
  dwellTracking(courseId);
}
