const SELECTOR_FORM = '.js-sign-up-form';

export default function setupSignUpValidation() {
  const $form = $(SELECTOR_FORM);

  $form.on('submit', e => {
    const termsCheckbox = $form.find('input#user_terms')[0];
    if (!termsCheckbox.checked) {
      e.preventDefault();
      e.stopImmediatePropagation();
      renderValidationError($form, termsCheckbox);
      changeValidationsOnCheck($form, termsCheckbox);
    }
  });
}

function renderValidationError($form, termsCheckbox) {
  $form
    .find('.invalid-feedback')
    .text(
      'Please review and agree to the terms and privacy policy to create an account.'
    )
    .show();

  termsCheckbox.classList.add('is-invalid');
  termsCheckbox.focus();
}

function changeValidationsOnCheck($form, termsCheckbox) {
  $(termsCheckbox).one('change', e => {
    if (e.target.checked) {
      $form
        .find('.invalid-feedback')
        .text('')
        .hide();

      e.target.classList.remove('is-invalid');
    }
  });
}
