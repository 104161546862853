import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["video", "autoplay"];

  declare videoTarget: HTMLVideoElement;
  declare autoplayTarget: HTMLElement;

  private autoPlayClass = "vjs-autoplay--hidden";

  async play() {
    const video = this.videoTarget.querySelector(
      ".vjs-tech"
    ) as HTMLVideoElement;
    try {
      if (
        !video.paused ||
        !this.autoplayTarget.classList.contains(this.autoPlayClass)
      )
        return;
      await video.play();
    } catch (error) {
      /* eslint-disable no-console */
      // Ideally we would report any errors here to Sentry
      // however that is not currently active
      console.log(error);
      /* eslint-enable no-console */
    }
  }
}
