import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["previous", "next"];

  private CARD_COUNT = 4;

  declare list: HTMLOListElement;
  declare previousTarget: HTMLButtonElement;
  declare nextTarget: HTMLButtonElement;
  declare observer: IntersectionObserver;
  declare firstElement: HTMLLIElement;
  declare lastElement: HTMLLIElement;

  async next() {
    await this.scrollAndFinish(this.list, this.getScrollAmount());
    this.updateNavigationButtonStates();
  }

  async prev() {
    await this.scrollAndFinish(this.list, -this.getScrollAmount());
    this.updateNavigationButtonStates();
  }

  getGapValue() {
    const computedStyle = window.getComputedStyle(this.list);
    return parseInt(computedStyle.gap);
  }

  getScrollAmount() {
    return this.list.clientWidth + this.getGapValue();
  }

  updateObservedElements() {
    // Disconnect previous observations
    this.observer.disconnect();

    if (this.list) {
      // Update first and last elements
      this.firstElement = this.list.firstElementChild as HTMLLIElement;
      this.lastElement = this.list.lastElementChild as HTMLLIElement;

      // Re-observe the updated first and last elements
      if (this.firstElement) this.observer.observe(this.firstElement);
      if (this.lastElement) this.observer.observe(this.lastElement);
    }
  }

  updateNavigationButtonStates() {
    this.list = this.element.querySelector("ol");

    const observerCallback = (entries: Array<IntersectionObserverEntry>) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          if (entry.target === this.firstElement) {
            this.previousTarget.setAttribute("disabled", "disabled");
          } else if (entry.target === this.lastElement) {
            this.nextTarget.setAttribute("disabled", "disabled");
          }
        } else {
          if (entry.target === this.firstElement) {
            this.previousTarget.removeAttribute("disabled");
          } else if (entry.target === this.lastElement) {
            this.nextTarget.removeAttribute("disabled");
          }
        }
      });
    };

    this.observer = new IntersectionObserver(observerCallback);

    this.updateObservedElements();
  }

  showNavigation({ detail }) {
    this.previousTarget.classList.toggle(
      "invisible",
      detail.hits <= this.CARD_COUNT
    );
    this.nextTarget.classList.toggle(
      "invisible",
      detail.hits <= this.CARD_COUNT
    );
  }

  scrollAndFinish(element: HTMLElement, scrollAmount: number) {
    return new Promise<void>((resolve) => {
      element.offsetHeight;

      element.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });

      let lastScrollLeft = element.scrollLeft;
      let lastScrollTop = element.scrollTop;

      setTimeout(() => {
        const checkIfScrollEnded = () => {
          const currentScrollLeft = element.scrollLeft;
          const currentScrollTop = element.scrollTop;

          if (
            currentScrollLeft === lastScrollLeft &&
            currentScrollTop === lastScrollTop
          ) {
            resolve();
          } else {
            lastScrollLeft = currentScrollLeft;
            lastScrollTop = currentScrollTop;
            requestAnimationFrame(checkIfScrollEnded);
          }
        };

        requestAnimationFrame(checkIfScrollEnded);
      }, 100);
    });
  }
}
