import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "section", "focus"];

  private WAIT_TIME = 400;

  declare sectionTarget: HTMLElement;
  declare focusTarget: HTMLElement;
  declare buttonTarget: HTMLButtonElement;

  scrollToSection(e: Event) {
    e.preventDefault();

    this.sectionTarget.scrollIntoView({
      behavior: "smooth",
    });

    this.buttonTarget.blur();

    const target = e.currentTarget as HTMLAnchorElement;

    history.pushState({}, "", target.hash);

    // Wait a small amount of time in order for the scrolling to occur
    setTimeout(() => this.focusTarget.focus(), this.WAIT_TIME);
  }
}
