import { Controller } from "@hotwired/stimulus";

export default class Reveal extends Controller {
  static targets = ["item"];
  static classes = ["hidden"];

  static values = {
    open: {
      default: false,
      type: Boolean,
    },
  };

  declare hiddenClass: string;
  declare itemTargets: HTMLElement[];
  declare openValue: boolean;

  toggle() {
    this.itemTargets.forEach((item) => {
      item.classList.toggle(this.hiddenClass);
    });
    this.openValue = !this.openValue;

    this.dispatch("toggled", {
      detail: {
        open: this.openValue,
      },
    });
  }

  show() {
    this.itemTargets.forEach((item) => {
      item.classList.toggle(this.hiddenClass, false);
    });
  }

  hide() {
    this.itemTargets.forEach((item) => {
      item.classList.toggle(this.hiddenClass, true);
    });
  }
}
