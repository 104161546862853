import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "successfulContainer",
    "errorContainer",
    "formContainer",
    "emailInput",
    "submitButton",
  ];

  declare emailInputTarget: HTMLInputElement;
  declare hasEmailInputTarget: HTMLInputElement;
  declare errorContainerTarget: HTMLElement;
  declare successfulContainerTarget: HTMLElement;
  declare formContainerTarget: HTMLElement;
  declare submitButtonTarget: HTMLButtonElement;

  connect() {
    this.bindMethods();
    this.element.addEventListener("ajax:success", this.onAjaxSuccess);
    this.element.addEventListener("ajax:error", this.onAjaxError);

    if (this.hasEmailInputTarget) {
      this.emailInputTarget.addEventListener("input", this.onEmailInputChange);
    }
  }

  disconnect() {
    this.element.removeEventListener("ajax:success", this.onAjaxSuccess);
    this.element.removeEventListener("ajax:error", this.onAjaxError);

    if (this.hasEmailInputTarget) {
      this.emailInputTarget.removeEventListener(
        "input",
        this.onEmailInputChange
      );
    }
  }

  bindMethods() {
    this.onAjaxSuccess = this.onAjaxSuccess.bind(this);
    this.onAjaxError = this.onAjaxError.bind(this);
    this.onEmailInputChange = this.onEmailInputChange.bind(this);
  }

  onAjaxSuccess() {
    this.successfulContainerTarget.classList.remove("d-none");
    this.formContainerTarget.classList.add("d-none");
  }

  onAjaxError() {
    this.errorContainerTarget.classList.remove("d-none");
    this.formContainerTarget.classList.add("d-none");
  }

  onEmailInputChange() {
    const validEmail = this.validateEmail(this.emailInputTarget.value);
    this.submitButtonTarget.disabled = !validEmail;
  }

  validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }
}
