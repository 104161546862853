import { isMobileDevice, getIOSVersionNumber } from './helpers';

export default function setupPauseVideoOnVisibilityChangeOnMobile() {
  const iOSVersion = getIOSVersionNumber();
  const reloadPageOnReturnToVisible =
    typeof iOSVersion === 'number' && iOSVersion <= 12;

  // When on any mobile device, pause all videos when document visibility
  // is hidden. Resume when visible for bg video
  if (isMobileDevice()) {
    $(document).on('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        pauseVideos();
      } else if (document.visibilityState === 'visible') {
        if (reloadPageOnReturnToVisible) {
          // Reload page if internet connection is present, restoring scroll position
          if (window.navigator.onLine) {
            const scrollPosition = window.scrollY;
            $(document).one('turbolinks:load', () => {
              $(window).scrollTop(scrollPosition);
            });
            window.Turbolinks.visit(location.toString());
          }
        } else {
          resumeBackgroundVideo();
        }
      }
    });
  }
}

function pauseVideos() {
  $('video').each((ind, video) => {
    video.pause();
  });
}

function resumeBackgroundVideo() {
  const bgVideo = $('.bm-hero__bg-video');

  if (bgVideo.length) {
    $(bgVideo)
      .find('video')[0]
      .play();
  }
}
