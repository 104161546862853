import { getIOSVersionNumber } from './helpers';
import { playNextLesson } from './play_next_lesson';
import { transitionElement } from './helpers/transition_element';
import { getCSRFToken } from "../../javascripts/utils";

const SELECTOR_CONTAINER = '.vjs-autoplay__container';
const SELECTOR_CONTENT = '.vjs-autoplay__content';
const SELECTOR_TIMER_TIME = '.vjs-autoplay__timer-time';
const SELECTOR_TIMER_BAR = '.vjs-autoplay__timer-bar';
const SELECTOR_CANCEL = '.js-autoplay-cancel';
const SELECTOR_RESTART = '.js-autoplay-restart';
const SELECTOR_LESSON_COMPLETED = '.vjs-autoplay__lesson-completed';
const SELECTOR_LESSON_LIKE_BUTTON = ".js-next-lesson";

const CLASS_HIDDEN = 'vjs-autoplay--hidden';

let _isAutoplaying = false;

export default function setupNextLessonAutoplay(player) {
  appendNextLessonDialog(player);

  $(document).on('turbolinks:before-cache', () => {
    resetAutoplay(player);
    player.off('ended', autoplayInit);
    player.off('lessonLoaded', cancelAutoplayOnLastLesson);
  });

  player.on('ended', autoplayInit);
  player.on('lessonLoaded', cancelAutoplayOnLastLesson);
  player.on('lessonLoaded', () => {
    updateNextLessonTitle(player);
  });

  function cancelAutoplayOnLastLesson() {
    if (!getNextLessonUrl(player)) {
      player.off('ended', autoplayInit);
      player.off('lessonLoaded', cancelAutoplayOnLastLesson);
    }
  }

  function autoplayInit() {
    if (!_isAutoplaying) {
      _isAutoplaying = true;

      // Track whether we were full screen before loading
      // the next video
      let wasFullscreen = player.el().classList.contains('vjs-fullscreen');
      // To let people see the next lesson overlay
      // we need to exit fullscreen on iOS
      if (wasFullscreen && typeof getIOSVersionNumber() === 'number') {
        player.exitFullscreen();
      }
      // Ensure animations are finished before beginning timer
      animateDialogIn(player)
        .then(() => {
          startTimer(player, wasFullscreen);
          return false;
        })
        .catch(() => null);
    }
  }
}

// ***** TIMER ***** \\

const TIME_LIMIT_DEFAULT = 8;

function startTimer(
  player,
  wasFullscreen,
  customStartTime = false
) {
  const $progressTime = $(player.el()).find(SELECTOR_TIMER_TIME);
  const $progressBar = $(player.el()).find(SELECTOR_TIMER_BAR);
  player.el().setAttribute('data-timer-running', 'true');

  let timeLimit;
  let timePassed;
  let timeLeft;

  if (customStartTime) {
    timeLimit = player.el().dataset.timeLimit; // 5
    timePassed = timeLimit - customStartTime; // 5 - 1 = 4
    timeLeft = customStartTime; // 1
  } else {
    timeLimit = TIME_LIMIT_DEFAULT;
    timePassed = 0;
    timeLeft = timeLimit;
    player.el().setAttribute('data-time-limit', timeLimit);
  }

  let timerInterval = null;
  player.el().setAttribute('data-time-left', timeLeft); // 1

  const barWidth = customStartTime
    ? 100 - (100 / timeLimit) * timeLeft
    : 100 / timeLimit; // 100 - (1)
  $progressBar.css('width', `${barWidth}%`);
  $progressTime.text(timeLeft);

  timerInterval = setInterval(() => {
    // Update Time
    timePassed += 1;
    timeLeft = timeLimit - timePassed;

    // Update UI
    $progressTime.text(timeLeft);
    const intervalBarWidth = (100 / timeLimit) * (timePassed + 1);
    $progressBar.css('width', `${intervalBarWidth}%`);
    player.el().setAttribute('data-time-left', timeLeft);

    // Check for timer end
    if (timeLeft === 0) {
      clearInterval(timerInterval);
      player.el().removeAttribute('data-timer-running');
      navigateToNextLesson(player, true, wasFullscreen);
    }
  }, 1000);

  $(document).on('turbolinks:before-cache', () => {
    clearInterval(timerInterval);
    timerInterval = null;
  });

  setupEvents(player, timerInterval);
}

// ***** NAVIGATION ***** \\

export function getNextLessonUrl(player, shouldAutoplay) {
  let url = player.el().dataset.nextLessonPath;

  if (!url) return;

  if (shouldAutoplay) {
    url += "?autoplay=true#lesson-player";
  }

  if (/debug_subtitles/.test(window.location)) {
    if (url.indexOf('?') === -1) {
      url += '?';
    } else {
      url += '&';
    }
    url += 'debug_subtitles=true';
  }

  return url;
}

function navigateToNextLesson(player, shouldAutoplay, wasFullscreen) {
  const url = getNextLessonUrl(player, shouldAutoplay);

  _isAutoplaying = false;

  // When not autoplaying, use a regular Turbolinks visit
  // to avoid handling multiple cases
  if (!shouldAutoplay) {
    return window.Turbolinks.visit(url);
  }

  if (wasFullscreen && typeof getIOSVersionNumber() === 'number') {
    // Unfortunately iOS < 11 doesn't play well
    // with us changing the source of the video
    // and restoring fullscreen so we use a default
    // Turbolinks visit
    if (getIOSVersionNumber() < 11) {
      return window.Turbolinks.visit(url);
    }

    // Before restore the video to full screen on iOS
    // to get people back to the state they were
    player.requestFullscreen();
  }

  $(document).off('show.bs.modal', window.pauseTimerEvent);

  return Promise.all([resetAutoplay(player), playNextLesson(player, url)]);
}

function updateNextLessonTitle(player) {
  player
    .el()
    .querySelector(
      `.${CLASS_NEXT_LESSON_TITLE}`
    ).textContent = player.getAttribute('data-next-lesson-title');
}

// ***** RESTART ***** \\

function restartLesson(player) {
  $(player.el())
    .find(SELECTOR_CONTAINER)
    .addClass(CLASS_HIDDEN);

  player.play();
  setTimeout(() => {
    player.currentTime(0);
  }, 10);

  resetAutoplay(player);
}

// ***** EVENTS ***** \\

function setupEvents(player, timerInterval) {
  // Setup cancel event
  $(player.el())
    .find(SELECTOR_CANCEL)
    .on('click', () => {
      clearInterval(timerInterval);
      player.el().removeAttribute('data-timer-running');
      timerInterval = null;
      resetAutoplay(player);
    });

  // Setup restart event
  $(player.el())
    .find(SELECTOR_RESTART)
    .on('click', () => {
      clearInterval(timerInterval);
      player.el().removeAttribute('data-timer-running');
      timerInterval = null;
      restartLesson(player);
    });

  // Pause timer if a modal is opened
  let remainingTime;
  $(document).on('show.bs.modal', '.modal', pauseTimer);

  $(document).on('turbolinks:before-cache', () => {
    $(document).off('show.bs.modal', pauseTimer);
  });

  window.pauseTimerEvent = pauseTimer;

  function pauseTimer() {
    if (player.el().dataset.timerRunning) {
      clearInterval(timerInterval);
      remainingTime = player.el().dataset.timeLeft;

      // Set the bar back to it's last whole value to avoid
      // the bar visually moving back then forwards when resuming
      const timelimit = player.el().dataset.timeLimit;
      const $progressBar = $(player.el()).find(SELECTOR_TIMER_BAR);
      const barWidth = 100 - (100 / timelimit) * remainingTime;

      // Add a slight delay so to change happens whilst the modal
      // is already overlayed
      setTimeout(() => {
        $progressBar.css('width', `${barWidth}%`);
      }, 500);

      $(document).off('show.bs.modal', pauseTimer);
      $(document).on('hidden.bs.modal', '.modal', resumeTimer);
    }
  }

  // Resume the timer on modal close. Skip 1 second to avoid
  // a visual delay in the timer restarting
  function resumeTimer() {
    if (player.el().dataset.timerRunning) {
      remainingTime = remainingTime > 1 ? remainingTime - 1 : remainingTime;
      startTimer(player, false, remainingTime);
      $(document).off('hidden.bs.modal', resumeTimer);
    }
  }
}

// ***** TRANSITIONS ***** \\

async function animateDialogIn(player) {
  player.addClass('vjs-big-play-button--hidden');
  await transitionElement(
    player.el().querySelector(SELECTOR_CONTAINER),
    "remove",
    CLASS_HIDDEN
  );

  await transitionElement(
    player.el().querySelector(SELECTOR_CONTENT),
    'remove',
    CLASS_HIDDEN
  );
}

// ***** MARKUP ***** \\

function appendNextLessonDialog(player) {
  const nextLessonTitle = player.el().dataset.nextLessonTitle;

  // Create markup
  const $wrapper = $(
    '<div class="vjs-autoplay__container vjs-autoplay--hidden" data-play-video-target="autoplay"></div>'
  ).html(getMarkup(nextLessonTitle, player));

  $wrapper.find(SELECTOR_LESSON_COMPLETED).hide();

  // Append to DOM
  $(player.el()).append($wrapper);

  // Get the play next button as part of the video end card
  // and add an event listener in order to move to the next lesson
  const playNextBtn = document.querySelector(SELECTOR_LESSON_LIKE_BUTTON);

  if (!playNextBtn) return;

  playNextBtn.addEventListener("click", () => {
    const url = getNextLessonUrl(player, true);
    playNextLesson(player, url);
  });
}

export const CLASS_NEXT_LESSON_TITLE = 'js-next-lesson__title';

function getMarkup(nextLessonTitle, player) {
  const csrfToken = getCSRFToken();
  const lessonId = player.el().dataset.lessonId;
  const likedStatus = player.el().dataset.likedStatus;
  const likeButtonText = likedStatus == "true" ? "Saved" : "Save lesson";

  function likeButtonBox() {
    if (!lessonId) return "";

    return `
      <div class="vjs-autoplay__like-box mt-sm-auto" data-controller="button-toggle" data-button-toggle-active-value="${likedStatus}" data-button-toggle-on-value="You saved this lesson" data-button-toggle-off-value="Save this lesson">
      <p class="d-none mb-3 text-neutral-50 font-sm d-sm-inline-block">Do you want to save this lesson?</p>
      <form data-remote="true" action="/lesson/${lessonId}/likes/toggle" accept-charset="UTF-8" method="post">
        <input type="hidden" name="authenticity_token" value="${csrfToken}" autocomplete="off">
        <button name="button" type="submit" class="btn mt-sm-2 btn--icon btn--icon-only vjs-autoplay__like-button" data-button-toggle-target="button" data-status="${likedStatus}">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m12 16 7 5V5a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v16l7-5Z"/>
          </svg>
          <span class="sr-only">${likeButtonText}</span>
        </button>
      </form>
    </div>
    `;
  }

  return `
    <div class="vjs-autoplay__content vjs-autoplay--hidden">
      <div class="vjs-autoplay__lesson-completed bm-sub-heading--sm">Lesson Completed</div>
      <div class="${lessonId ? "mt-sm-auto" : "m-auto"}">
        <div class="vjs-autoplay__up-next bm-sub-heading--sm text-neutral-50">Up next</div>
        <button class="vjs-autoplay__lesson-title ${CLASS_NEXT_LESSON_TITLE}">${nextLessonTitle}</button>

        <div class="vjs-autoplay__timer">
          <div class="vjs-autoplay__timer-time"></div>
          <div class="vjs-autoplay__timer-bar-container">
            <div class="vjs-autoplay__timer-bar"></div>
          </div>
          <button class="vjs-autoplay__btn vjs-autoplay__btn-cancel js-autoplay-cancel m-auto">
            <span class="vjs-autoplay__btn--cancel"></span>
            <span class="font-sm text-neutral-50">Cancel</span>
          </button>
        </div>
      </div>
      ${likeButtonBox()}
      <div class="vjs-autoplay__btn-box ${lessonId ? "" : "mt-auto"}">
        <div class="align-items-center d-flex">
          <button class="vjs-autoplay__btn js-autoplay-restart">
            <span class="align-items-center d-flex justify-content-center vjs-autoplay__icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19"><g fill="none" fill-rule="evenodd" stroke="#fff" stroke-width="2"><path d="M13.667 1.083 17 4.417 13.667 7.75"/><path d="M2 9.5V7.833A3.333 3.333 0 0 1 5.333 4.5H16M5.333 17.583 2 14.25l3.333-3.333"/><path d="M17 9.167v1.666a3.333 3.333 0 0 1-3.333 3.334H3"/></g></svg>
            </span>
            <span class="sr-only d-sm-none">Rewatch</span>
            <span class="d-none bg-transparent font-xs text-neutral-50 ml-2 d-sm-inline-block">Rewatch</span>
          </button>
        </div>
        <div class="align-items-center d-flex">
          <button class="vjs-autoplay__btn js-next-lesson">
            <span class="sr-only d-sm-none">Play next</span>
            <span class="d-none bg-transparent font-xs text-neutral-50 mr-2 d-sm-inline-block">Play next</span>
            <span class="align-items-center d-flex justify-content-center vjs-autoplay__icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12.5 10 4.167 3.335v13.333l8.333-6.666Z"/><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.833 4.166v11.667"/></svg>
            </span>
          </button>
        </div>
      </div>
    </div>`;
}

export function resetAutoplay(player) {
  $(player.el()).removeClass('vjs-big-play-button--hidden');
  $(player.el())
    .find(SELECTOR_LESSON_COMPLETED)
    .hide();

  const $progressTime = $(player.el()).find(SELECTOR_TIMER_TIME);
  const $progressBar = $(player.el()).find(SELECTOR_TIMER_BAR);

  $progressTime.text('');
  $progressBar.css('width', '0');

  _isAutoplaying = false;

  if (player) {
    // Allow to listen to the transition being finished
    return Promise.all([
      transitionElement(
        player.el().querySelector(SELECTOR_CONTAINER),
        'add',
        CLASS_HIDDEN
      ),
      transitionElement(
        player.el().querySelector(SELECTOR_CONTENT),
        'add',
        CLASS_HIDDEN
      )
    ]);
  }
}
