const SELECTOR_CTA = '.js-mobile-sticky-cta';
const SELECTOR_TRIGGER = '.js-mobile-sticky-trigger';
const CLASS_STICKY = 'bm-btn-box--fixed';

const breakpoint = 575;

export default function setupMobileStickyCta() {
  init();

  $(document).on('turbolinks:load', () => {
    init();
  });

  $(document).on('turbolinks:before-cache', () => {
    $(SELECTOR_CTA).removeClass(CLASS_STICKY);
  });

  function init() {
    if ($(SELECTOR_CTA).length) {
      if ($(window).width() <= breakpoint) {
        $(SELECTOR_CTA).addClass(CLASS_STICKY);
      }

      let wasAbove = false;

      const stickyCtaObserver = new IntersectionObserver(
        entries => {
          // Calculate if element came from above or below viewport
          const isAbove =
            entries[0].boundingClientRect.y > entries[0].rootBounds.y;

          if (entries[0].isIntersecting) {
            if (wasAbove) {
              $(SELECTOR_CTA).addClass(CLASS_STICKY);
            }
          } else {
            // Once element has left viewport,
            // if trigger is not at the top of the page
            if (entries[0].boundingClientRect.y > 0) {
              $(SELECTOR_CTA).removeClass(CLASS_STICKY);
            }
          }
          wasAbove = isAbove;
        },
        { threshold: [0.5] }
      );

      stickyCtaObserver.observe($(SELECTOR_TRIGGER)[0]);
      $(document).on('turbolinks:before-cache', teardown);

      // eslint-disable-next-line no-inner-declarations
      function teardown() {
        stickyCtaObserver.unobserve($(SELECTOR_TRIGGER)[0]);
        $(document).off('turbolinks:before-cache', teardown);
      }
    }
  }
}
